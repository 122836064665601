// DefaultContent.jsx

import { getFunctions, httpsCallable } from "firebase/functions";
import { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { VintedItemContext } from "../../context/VintedItemContext";
import {
  Button,
  Spinner,
  Input,
  Chip,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";
import { SearchIcon } from "../../icons/SearchIcon";
import PasteIcon from "./PasteIcon";
import CrossIcon from "../../icons/CrossIcon";
import List from "../../components/list/List";
import useHighlight from "../../components/useImportHighlight";
import { motion } from "framer-motion";
import MoneyIcon from "../../icons/MoneyIcon";

export default function DefaultContent(props) {
  const [vintedInput, setVintedInput] = useState("");
  const [vintedLoading, setVintedLoading] = useState(false);
  const [error, setError] = useState(null);
  const { dispatch } = useContext(VintedItemContext);

  const handleVintedSearch = async () => {
    if (!vintedInput) return; // Do nothing if input is empty

    setVintedLoading(true);
    setError(null);

    try {
      let productId = vintedInput.split("/").slice(-1)[0].split("-")[0];

      if (!productId || isNaN(productId) || productId.length < 8) {
        throw new Error(
          "Le lien est invalide. Veuillez vérifier l'URL du produit."
        );
      }

      const functions = getFunctions();
      const getVintedProductData = httpsCallable(
        functions,
        "getVintedProductData"
      );

      const result = await getVintedProductData({ productId });

      if (result.data) {
        dispatch({ type: "ADD", payload: result.data });
        setVintedInput(""); // Clear input after successful import
      } else {
        throw new Error("Données du produit non trouvées");
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setVintedLoading(false);
    }
  };

  const [items, setItems] = useState([]);
  const [freeItems, setFreeItems] = useState([]);

  useEffect(() => {
    if (props.items) {
      let items = props.items.filter((el) => el.status === "saved");
      let freeItems = props.items.filter((el) => el.status === "free");
      setItems(items);
      setFreeItems(freeItems);
    }
  }, [props]);

  const isImportHighlighted = useHighlight("importHighlight");
  const isStartSellHighlighted = useHighlight("startSellHighlight");

  // Import useLocation to access query parameters
  const location = useLocation();

  // Function to check screen size
  const checkScreenSize = () => {
    return window.innerWidth < 768; // Tailwind's 'md' breakpoint is 768px
  };
  // State to track screen size
  const [isMobile, setIsMobile] = useState(checkScreenSize());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkScreenSize());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // State to manage expanded accordion items
  const [expandedKeys, setExpandedKeys] = useState(() => {
    const initialKeys = isMobile ? [] : ["1", "2", "3"];
    if (isImportHighlighted && !initialKeys.includes("1")) {
      initialKeys.push("1");
    }
    return initialKeys;
  });

  useEffect(() => {
    setExpandedKeys(() => {
      const initialKeys = isMobile ? [] : ["1", "2", "3"];
      if (isImportHighlighted && !initialKeys.includes("1")) {
        initialKeys.push("1");
      }
      return initialKeys;
    });
  }, [isImportHighlighted]);

  // Handler for accordion change
  const handleAccordionChange = (keys) => {
    setExpandedKeys(keys);
  };

  // Animation variants for Framer Motion
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  // Handler for pasting content from clipboard
  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setVintedInput(text);
    } catch (err) {
      console.error("Échec de la lecture du presse-papiers : ", err);
    }
  };

  // Handler for clearing input
  const handleClearInput = () => {
    setVintedInput("");
  };

  return (
    <div className="w-full">
      <Accordion
        isCompact
        variant="splitted"
        selectedKeys={expandedKeys}
        onSelectionChange={handleAccordionChange}
        className="space-y-1"
      >
        {/* Free Selling Offer Section */}
        <AccordionItem
          key="3"
          aria-label="Offre de lancement: vendez gratuitement"
          title={
            <div className="flex items-center gap-4">
              <div className="flex shrink-0 items-center justify-center w-10 h-10 bg-white text-blue-600 rounded-full text-lg font-bold">
                <MoneyIcon color="#2463EB" size={25} />
              </div>
              <h2 className="text-xl font-bold text-white">
                Offre de lancement: vendez gratuitement
              </h2>
            </div>
          }
          className="rounded-xl bg-gradient-to-r from-indigo-500 to-purple-500 py-2"
        >
          <motion.div
            className="p-2"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            <List
              isLoading={props.isLoading}
              data={freeItems}
              emptyMessage={
                <div className="flex flex-col items-center justify-center h-64 text-center">
                  {/* Removed EmptyIcon */}
                  <h2 className="text-xl font-semibold text-white">
                    Aucun article gratuit disponible pour le moment
                  </h2>
                </div>
              }
            />
          </motion.div>
        </AccordionItem>
        {/* Import Section */}
        <AccordionItem
          key="1"
          aria-label="Importez un article Vinted"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-blue-600 text-white rounded-full text-lg font-bold">
                1
              </div>
              <h2 className="text-xl font-bold text-white">
                Importez un article Vinted
              </h2>
            </div>
          }
          className="bg-gray-800 rounded-xl py-2"
        >
          <motion.div
            className={`p-2 rounded-md ${
              isImportHighlighted ? "animate-shine" : ""
            }`}
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            {error && (
              <Chip
                color="danger"
                size="sm"
                className="mb-4"
                startContent={<span className="font-bold">Erreur:</span>}
              >
                {error}
              </Chip>
            )}
            <div className="flex flex-col md:flex-row gap-4">
              <Input
                className="flex-1"
                placeholder="Entrez le lien Vinted de l'article désiré"
                startContent={<SearchIcon size={24} />}
                type="url"
                variant="bordered"
                value={vintedInput}
                onChange={(e) => setVintedInput(e.target.value)}
                size="lg"
                color="primary"
                endContent={
                  <div className="flex items-center gap-2">
                    {vintedInput && (
                      <button
                        onClick={handleClearInput}
                        className="focus:outline-none"
                      >
                        <CrossIcon
                          size={24}
                          color="#9CA3AF"
                          className="hover:text-gray-300"
                        />
                      </button>
                    )}
                  </div>
                }
              />
              <div className="flex flex-row-reverse gap-3">
                <Button
                  color="primary"
                  isDisabled={!vintedInput}
                  onPress={handleVintedSearch}
                  className="md:w-auto"
                  size="lg"
                >
                  {vintedLoading ? (
                    <Spinner color="white" size="sm" />
                  ) : (
                    "Importer"
                  )}
                </Button>{" "}
              </div>
            </div>
          </motion.div>
        </AccordionItem>

        {/* Start Selling Section */}
        <AccordionItem
          key="2"
          aria-label="Commencez la revente"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-blue-600 text-white rounded-full text-lg font-bold">
                2
              </div>
              <h2 className="text-xl font-bold text-white">
                Commencez la revente
              </h2>
            </div>
          }
          className="bg-gray-800 rounded-xl py-2"
        >
          <motion.div
            className={`p-2 ${isStartSellHighlighted ? "animate-shine" : ""}`}
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            <List
              isLoading={props.isLoading}
              data={items}
              emptyMessage={
                <div className="flex flex-col items-center justify-center h-64 text-center">
                  {/* Removed EmptyIcon */}
                  <h2 className="text-xl font-semibold text-gray-400">
                    Vous n'avez aucun article importé
                  </h2>
                  <Link
                    to="/home?importHighlight=true"
                    className="mt-4 text-blue-500 hover:underline"
                  >
                    Importer un article
                  </Link>
                </div>
              }
            />
          </motion.div>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
