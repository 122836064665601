const ProgressIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 4v16M7 4l4 4M7 4 3 8m12.6 3h3.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C21 10.24 21 9.96 21 9.4V5.6c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C20.24 4 19.96 4 19.4 4h-3.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C14 4.76 14 5.04 14 5.6v3.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C14.76 11 15.04 11 15.6 11m0 9h1.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C19 19.24 19 18.96 19 18.4v-1.8c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C18.24 15 17.96 15 17.4 15h-1.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C14 15.76 14 16.04 14 16.6v1.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C14.76 20 15.04 20 15.6 20"
    ></path>
  </svg>
);

export default ProgressIcon;
