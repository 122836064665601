// CustomPhoneInput.jsx

import React, { useState, useEffect, useRef, useMemo } from "react";
import { Input, Chip } from "@nextui-org/react";
import countryList from "country-list";
import {
  AsYouType,
  getCountryCallingCode,
  getCountries as getSupportedCountries,
  getExampleNumber,
} from "libphonenumber-js";

const CustomPhoneInput = ({
  value,
  onChange,
  countryCode,
  onCountryChange,
  error,
}) => {
  const supportedCountryCodes = useMemo(() => getSupportedCountries(), []);
  const allCountries = useMemo(() => {
    return countryList
      .getData()
      .filter((country) => supportedCountryCodes.includes(country.code));
  }, [supportedCountryCodes]);

  const countryCallingCode = getCountryCallingCode(countryCode.toUpperCase());

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(allCountries);
  const [maxLength, setMaxLength] = useState(15);
  const [internalValue, setInternalValue] = useState(value);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  // Set maxLength based on example number for the selected country
  useEffect(() => {
    const exampleNumber = getExampleNumber(countryCode.toUpperCase());
    if (exampleNumber && exampleNumber.nationalNumber) {
      setMaxLength(exampleNumber.nationalNumber.length);
    } else {
      setMaxLength(15);
    }
  }, [countryCode]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
        setSearchQuery("");
        setFilteredCountries(allCountries);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, allCountries]);

  // Filter countries based on search query
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredCountries(allCountries);
    } else {
      const query = searchQuery.toLowerCase();
      const filtered = allCountries.filter((country) => {
        const callingCode = getCountryCallingCode(country.code);
        return (
          country.name.toLowerCase().includes(query) ||
          callingCode.includes(query)
        );
      });
      setFilteredCountries(filtered);
    }
  }, [searchQuery, allCountries]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleCountrySelect = (code) => {
    onCountryChange(code);
    setIsDropdownOpen(false);
    setSearchQuery("");
    setFilteredCountries(allCountries);
    // Reset phone number when country changes
    setInternalValue("");
    onChange("");
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    const digitsOnly = input.replace(/\D/g, "");

    let processedDigits = digitsOnly;

    // For French numbers: if the user starts with '6', prepend '0' internally.
    if (
      countryCode.toLowerCase() === "fr" &&
      processedDigits.length > 0 &&
      processedDigits[0] !== "0"
    ) {
      processedDigits = "0" + processedDigits;
    }

    // Limit input to maxLength digits
    const limitedDigits = processedDigits.slice(0, maxLength);

    const formatter = new AsYouType(countryCode.toUpperCase());
    const formattedNumber = formatter.input(limitedDigits);

    setInternalValue(formattedNumber.slice(1));

    if (formattedNumber !== value) {
      onChange(digitsOnly);
    }
  };

  return (
    <div className="relative">
      <Input
        size="lg"
        variant="underlined"
        type="tel"
        label="Numéro de téléphone"
        placeholder="6 12 34 56 78"
        value={internalValue}
        onChange={handlePhoneNumberChange}
        startContent={
          <div className="flex items-center">
            <button
              type="button"
              onClick={toggleDropdown}
              ref={buttonRef}
              className="flex items-center text-white focus:outline-none"
            >
              <span className="text-default-400 text-small">
                +{countryCallingCode}
              </span>
              <svg
                className="w-4 h-4 ml-1 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M6 9l6 6 6-7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        }
        className="w-full"
      />
      {isDropdownOpen && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-10"></div>
          <div
            ref={dropdownRef}
            className="absolute top-full left-0 right-0 bg-gray-800 mt-1 rounded-md shadow-lg max-h-60 overflow-y-auto z-20"
          >
            <div className="p-2">
              <input
                type="text"
                placeholder="Rechercher un pays"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-2 bg-gray-700 text-white rounded-md focus:outline-none"
              />
            </div>
            <ul className="py-1">
              {filteredCountries.map((country) => {
                const callingCode = getCountryCallingCode(country.code);
                return (
                  <li
                    key={country.code}
                    onClick={() =>
                      handleCountrySelect(country.code.toLowerCase())
                    }
                    className="px-4 py-2 hover:bg-gray-700 cursor-pointer text-white"
                  >
                    {country.name} (+{callingCode})
                  </li>
                );
              })}
              {filteredCountries.length === 0 && (
                <li className="px-4 py-2 text-gray-400">Aucun pays trouvé</li>
              )}
            </ul>
          </div>
        </>
      )}
      {error && (
        <div className="mt-2">
          <Chip size="sm" color="danger">
            {error}
          </Chip>
        </div>
      )}
    </div>
  );
};

export default CustomPhoneInput;
