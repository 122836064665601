const DangerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    className="icon"
    viewBox="0 -52 1128 1128"
  >
    <path
      fill={props.color}
      d="m1103.834 757.26-192.908-334.2-192.91-334.201C686.003 33.159 628.342 0 564.148 0 499.79 0 442.293 33.159 410.115 88.859L24.134 757.26c-32.179 55.7-32.179 122.181 0 177.881S113.81 1024 178.167 1024h771.798c64.357 0 121.854-33.159 154.032-88.859s32.016-122.18-.163-177.88m-84.775 128.715a78.78 78.78 0 0 1-69.094 39.856H178.167c-28.912 0-54.72-14.865-69.094-39.856a79.37 79.37 0 0 1 0-79.875L494.89 137.862a79.22 79.22 0 0 1 69.257-39.856c28.912 0 54.72 14.864 69.095 39.856l385.817 668.401a79.06 79.06 0 0 1 0 79.712m-451.971-262.82c27.115 0 49.003-21.888 49.003-49.003V275.397c0-27.115-21.888-49.003-49.003-49.003s-49.003 21.888-49.003 49.003v298.755c0 26.952 21.888 49.003 49.003 49.003m0 66.971c-43.45 0-78.732 35.282-78.732 78.732s35.282 78.731 78.732 78.731 78.731-35.282 78.731-78.731-35.282-78.732-78.731-78.732m0 98.006c-10.618 0-19.275-8.657-19.275-19.274s8.657-19.275 19.275-19.275 19.274 8.657 19.274 19.275-8.657 19.274-19.274 19.274"
    ></path>
  </svg>
);

export default DangerIcon;
