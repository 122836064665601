// WelcomeLayout.jsx

import React, { useState, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../icons/Logo"; // Assurez-vous que le chemin est correct
import { AuthContext } from "../../context/AuthContext"; // Ajustez le chemin si nécessaire
import { motion, AnimatePresence } from "framer-motion";

const MenuIcon = ({ open }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 text-gray-300 hover:text-blue-500 transition duration-300"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    {open ? (
      // Icône de fermeture (X)
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
      />
    ) : (
      // Icône hamburger
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 6h16M4 12h16M4 18h16"
      />
    )}
  </svg>
);

const WelcomeLayout = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Variantes Framer Motion pour le menu mobile
  const menuVariants = {
    hidden: {
      x: "-100%",
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    visible: {
      x: "0%",
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    exit: {
      x: "-100%",
      transition: { duration: 0.3, ease: "easeInOut" },
    },
  };

  // Variantes pour l'overlay
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 0.5 },
    exit: { opacity: 0 },
  };

  // Fonction pour défiler vers le haut
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="md:min-h-screen bg-gray-900 text-white">
      {/* Barre de navigation */}
      <header className="fixed top-0 left-0 right-0 z-50 bg-gray-900 bg-opacity-90 backdrop-filter backdrop-blur-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo et Titre */}
            <div className="flex items-center justify-end">
              <Link
                to="/welcome"
                className="flex items-end"
                onClick={scrollToTop}
              >
                <Logo color="white" size={80} />
                <span className="mb-[-2px] font-bold text-small tracking-tight text-blue-500">
                  Beta.
                </span>
              </Link>
            </div>
            {/* Liens de navigation (Desktop) */}
            <div className="hidden md:flex items-center space-x-6">
              <NavLink
                to="/exclusive-offer"
                className="relative text-lg font-medium text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 hover:opacity-80 transition duration-300"
                onClick={scrollToTop}
              >
                Offre Exclusive
              </NavLink>
              <NavLink
                to="/features"
                className="relative text-lg font-medium text-gray-300 hover:text-blue-500 transition duration-300"
                onClick={scrollToTop}
              >
                Fonctionnalités
                <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-blue-500 transform scale-x-0 transition-transform duration-300 origin-left hover:scale-x-100"></span>
              </NavLink>
              <NavLink
                to="/pricing"
                className="relative text-lg font-medium text-gray-300 hover:text-blue-500 transition duration-300"
                onClick={scrollToTop}
              >
                Tarifs
                <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-blue-500 transform scale-x-0 transition-transform duration-300 origin-left hover:scale-x-100"></span>
              </NavLink>

              <NavLink
                to="/contact"
                className="relative text-lg font-medium text-gray-300 hover:text-blue-500 transition duration-300"
                onClick={scrollToTop}
              >
                Contact
                <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-blue-500 transform scale-x-0 transition-transform duration-300 origin-left hover:scale-x-100"></span>
              </NavLink>
            </div>
            {/* Boutons */}
            <div className="hidden md:flex items-center space-x-4">
              {currentUser ? (
                <Button
                  auto
                  flat
                  color="primary"
                  onPress={() => navigate("/home")}
                >
                  Accéder à l'application
                </Button>
              ) : (
                <>
                  <Button
                    auto
                    flat
                    color="primary"
                    variant="bordered"
                    onPress={() => navigate("/login")}
                  >
                    Se connecter
                  </Button>
                  <Button
                    auto
                    color="primary"
                    onPress={() => navigate("/sign-up")}
                    className="bg-blue-600 text-white hover:bg-blue-700"
                  >
                    S'inscrire
                  </Button>
                </>
              )}
            </div>
            {/* Bouton du menu mobile */}
            <div className="md:hidden flex items-center">
              <button
                onClick={handleMobileMenuToggle}
                className="focus:outline-none"
                aria-label="Toggle menu"
              >
                <MenuIcon open={isMobileMenuOpen} />
              </button>
            </div>
          </div>
        </div>
      </header>
      {/* Contenu */}
      <main className="pt-16">
        <Outlet />
      </main>
      {/* Menu mobile et overlay */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <>
            {/* Overlay */}
            <motion.div
              className="fixed inset-0 bg-black z-40"
              variants={overlayVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onClick={() => setIsMobileMenuOpen(false)}
            />
            {/* Menu Mobile */}
            <motion.nav
              className="fixed top-0 left-0 bottom-0 w-3/4 max-w-xs bg-gray-800 z-50 shadow-lg"
              variants={menuVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <div className="px-5 pt-5 space-y-6">
                {/* Logo */}
                <Link
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    scrollToTop();
                  }}
                  to="/welcome"
                  className="flex items-end"
                >
                  <Logo color="white" size={80} />
                  <span className="mb-[-2px] font-bold text-small tracking-tight text-blue-500">
                    Beta.
                  </span>
                </Link>
                {/* Liens de navigation */}
                <div className="space-y-4">
                  <NavLink
                    to="/exclusive-offer"
                    className="block text-lg font-medium text-gray-300 hover:text-blue-500 transition duration-300"
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      scrollToTop();
                    }}
                  >
                    Offre Exclusive
                  </NavLink>
                  <NavLink
                    to="/features"
                    className="block text-lg font-medium text-gray-300 hover:text-blue-500 transition duration-300"
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      scrollToTop();
                    }}
                  >
                    Fonctionnalités
                  </NavLink>
                  <NavLink
                    to="/pricing"
                    className="block text-lg font-medium text-gray-300 hover:text-blue-500 transition duration-300"
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      scrollToTop();
                    }}
                  >
                    Tarifs
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className="block text-lg font-medium text-gray-300 hover:text-blue-500 transition duration-300"
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      scrollToTop();
                    }}
                  >
                    Contact
                  </NavLink>
                </div>
                {/* Boutons */}
                <div className="space-y-4 pt-4 border-t border-gray-700">
                  {currentUser ? (
                    <button
                      onClick={() => {
                        navigate("/home");
                        setIsMobileMenuOpen(false);
                      }}
                      className="w-full text-left px-3 py-2 rounded-md text-lg font-semibold text-white bg-blue-600 hover:bg-blue-700 transition duration-300"
                    >
                      Accéder à l'application
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          navigate("/login");
                          setIsMobileMenuOpen(false);
                        }}
                        className="w-full text-left px-3 py-2 rounded-md text-lg font-medium bg-blue-700 text-white hover:bg-blue-800 transition duration-300"
                      >
                        Se connecter
                      </button>
                      <button
                        onClick={() => {
                          navigate("/sign-up");
                          setIsMobileMenuOpen(false);
                        }}
                        className="w-full text-left px-3 py-2 rounded-md text-lg font-semibold text-white bg-blue-600 hover:bg-blue-700 transition duration-300"
                      >
                        S'inscrire
                      </button>
                    </>
                  )}
                </div>
              </div>
            </motion.nav>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WelcomeLayout;
