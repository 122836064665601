// ProfitIcon.jsx

import React from "react";

const ProfitIcon = ({ size = 24, color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width={size}
    height={size}
    fill={color}
    version="1.1"
    viewBox="0 0 314.965 314.965"
  >
    <g id="XMLID_93_">
      <path
        id="XMLID_102_"
        d="M104.982 15.004C44.896 15.004 0 38.758 0 59.995c0 21.238 44.896 44.992 104.982 44.992s104.983-23.754 104.983-44.992c.001-21.237-44.897-44.991-104.983-44.991"
      ></path>
      <path
        id="XMLID_103_"
        d="M0 239.97c0 21.237 44.896 44.991 104.982 44.991 8.769 0 17.211-.51 25.241-1.439a120 120 0 0 1-16.559-28.716c-2.881.097-5.771.164-8.683.164-34.333 0-66.847-6.803-91.55-19.155-4.864-2.432-9.34-5.045-13.431-7.811z"
      ></path>
      <path
        id="XMLID_104_"
        d="M104.982 194.977c-34.333 0-66.846-6.803-91.55-19.155-4.865-2.432-9.342-5.045-13.433-7.81v11.965c0 21.238 44.896 44.992 104.982 44.992.308 0 .612-.005.919-.006a120.6 120.6 0 0 1-.002-29.997c-.305.001-.608.011-.916.011"
      ></path>
      <path
        id="XMLID_105_"
        d="M104.982 134.987c-34.333 0-66.846-6.803-91.55-19.155-4.865-2.433-9.342-5.045-13.433-7.811v11.966c0 21.237 44.896 44.99 104.982 44.99q4.464-.001 8.811-.174a120.2 120.2 0 0 1 18.778-31.331 252 252 0 0 1-27.588 1.515"
      ></path>
      <path
        id="XMLID_106_"
        d="M149.981 160.244a89.5 89.5 0 0 0-13.408 32.762 90.2 90.2 0 0 0-.689 29.789 89.3 89.3 0 0 0 9.37 28.914 90.5 90.5 0 0 0 19.075 24.701c16.01 14.621 37.299 23.551 60.636 23.551 49.626 0 90-40.374 90-90 0-49.627-40.374-90-90-90-5.13 0-10.158.438-15.056 1.266-24.947 4.219-46.436 18.738-59.928 39.017m79.654 5.237c15.872 0 26.958 9.46 26.958 23.004 0 4.774-2.851 7.625-7.625 7.625-5.268 0-7.625-3.333-7.625-6.637 0-8.386-8.111-9.291-11.599-9.291-8.02 0-11.598 3.916-11.598 12.697v3.029h14.563c3.717 0 6.857 3.19 6.857 6.967 0 3.78-3.076 6.855-6.857 6.855h-14.563v2.546h14.563c3.717 0 6.857 3.191 6.857 6.966 0 3.78-3.076 6.855-6.857 6.855h-14.563v1.383c0 8.248 3.83 12.258 11.708 12.258 3.454 0 11.488-.927 11.488-9.512q0-.118.007-.236c.178-3.003 2.279-6.51 7.618-6.51 4.774 0 7.625 2.851 7.625 7.625 0 13.738-11.086 23.334-26.958 23.334-9.123 0-16.237-2.999-20.828-8.454-3.011-3.576-4.936-8.209-5.623-13.754a39 39 0 0 1-.287-4.751v-1.383h-2.811c-3.846 0-6.746-2.947-6.746-6.855 0-3.906 2.963-6.966 6.746-6.966h2.811v-2.546h-2.811c-3.792 0-6.659-2.868-6.739-6.694 0-.055-.007-.107-.007-.161 0-3.906 2.963-6.967 6.746-6.967h2.81v-3.029c0-8.751 2.464-15.624 7.069-20.299 4.56-4.626 11.219-7.099 19.671-7.099"
      ></path>
    </g>
  </svg>
);

export default ProfitIcon;
