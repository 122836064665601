import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import NavBar from "../navbar/NavBar";
import SelectedItem from "../selectedItem/SelectedItem";
import { VintedItemContext } from "../../context/VintedItemContext";
import EditItem from "../selectedItem/EditItem";
import DefaultContent from "./DefaultContent";
import { useWallet } from "../../context/WalletContext";
import { Spinner } from "@nextui-org/react";
import Logo from "../../icons/Logo";

export default function Layout(props) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedEditItem, setSelectedEditItem] = useState(null);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { vintedItem } = useContext(VintedItemContext);
  const { walletData } = useWallet();

  useEffect(() => {
    if (searchParams.has("item")) {
      setSelectedItem(searchParams.get("item"));
    } else {
      setSelectedItem(null);
    }
    if (searchParams.has("editItem")) {
      setSelectedEditItem(searchParams.get("editItem"));
    } else {
      setSelectedEditItem(null);
    }
  }, [location, searchParams]);

  return (
    <div className="w-screen md:h-screen flex flex-col md:flex-row">
      {/* Navbar */}
      <div className="flex-shrink-0">
        <NavBar />
      </div>

      {/*Mobile top Navbar */}
      <div className="md:hidden">
        <div className="w-full flex justify-between items-center pt-3 px-3">
          <div className="flex items-end">
            <Logo color="white" size={80} />
            <span className="mb-[-2px] font-bold text-small tracking-tight text-blue-500">
              Beta.
            </span>{" "}
          </div>{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500 font-semibold text-3xl">
            {walletData ? walletData.balance : <Spinner color="white" />}€
          </span>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-y-scroll overflow-x-hidden max-w-screen bg-gray-900 flex flex-col md:grid md:grid-cols-2 md:pb-4 pb-[95px] md:overflow-y-auto">
        {/* Left Content */}
        <div className="flex-1 p-1 md:pr-0 pt-3 md:pt-4 pb-0">
          {selectedItem || selectedEditItem || vintedItem ? (
            <div>
              {vintedItem ? (
                <EditItem vintedItem={vintedItem} />
              ) : selectedItem ? (
                <SelectedItem itemId={selectedItem} />
              ) : (
                <EditItem itemId={selectedEditItem} />
              )}
            </div>
          ) : (
            <DefaultContent items={props.items} isLoading={props.isLoading} />
          )}
        </div>

        {/* Right Content */}
        <div className="flex-1 shrink-0 p-1 md:pl-0 pt-3 md:pt-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
