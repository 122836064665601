// Login.jsx

import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { motion } from "framer-motion";
import { Input, Button, Chip } from "@nextui-org/react";
import { EyeIcon } from "../signup/EyeIcon";
import { EyeSlashFilledIcon } from "../../icons/EyeSlashFilledIcon";
import { AuthContext } from "../../context/AuthContext";
import { auth } from "../../firebase";

const Login = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  // Firebase error handling function
  const handleFirebaseError = (error) => {
    const errorMessages = {
      "auth/invalid-email": "L'adresse email n'est pas valide.",
      "auth/user-disabled": "Ce compte a été désactivé.",
      "auth/user-not-found": "Aucun utilisateur trouvé avec cet email.",
      "auth/wrong-password": "Le mot de passe est incorrect.",
      "auth/too-many-requests":
        "Trop de tentatives de connexion. Veuillez réessayer plus tard.",
      "auth/network-request-failed":
        "Problème de réseau. Veuillez vérifier votre connexion.",
      default: "Une erreur s'est produite. Veuillez réessayer.",
    };

    return errorMessages[error.code] || errorMessages.default;
  };

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        dispatch({ type: "LOGIN", payload: user });
        navigate("/home");
      })
      .catch((error) => {
        const errorMessage = handleFirebaseError(error);
        setError(errorMessage);
      });
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  // Framer Motion variants
  const containerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: 0.2, duration: 0.8, ease: "easeOut" },
    },
  };

  const inputVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: 0.4 + i * 0.1, duration: 0.6, ease: "easeOut" },
    }),
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.8, duration: 0.6, ease: "easeOut" },
    },
  };

  return (
    <div className="mt-[70px] flex items-center justify-center bg-gray-900 text-white px-5">
      <motion.div
        className="w-full max-w-md p-8 bg-gray-800 rounded-lg shadow-lg"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h2
          className="text-3xl font-bold mb-6 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
        >
          Heureux de vous revoir!
        </motion.h2>
        {error && (
          <motion.div
            className="mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Chip color="danger" className="w-full">
              {error}
            </Chip>
          </motion.div>
        )}
        <motion.div className="space-y-5" initial="hidden" animate="visible">
          <motion.div custom={0} variants={inputVariants}>
            <Input
              size="lg"
              variant="underlined"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              label="Email"
              placeholder="Entrez votre email"
              className="w-full"
              isClearable
            />
          </motion.div>
          <motion.div custom={1} variants={inputVariants}>
            <Input
              size="lg"
              variant="underlined"
              label="Mot de passe"
              placeholder="Entrez votre mot de passe"
              onChange={(e) => setPassword(e.target.value)}
              endContent={
                <button
                  onClick={toggleVisibility}
                  aria-label="toggle password visibility"
                  className="focus:outline-none"
                >
                  {isVisible ? (
                    <EyeIcon className="text-2xl text-gray-400" />
                  ) : (
                    <EyeSlashFilledIcon className="text-2xl text-gray-400" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
              className="w-full"
            />
          </motion.div>
          <motion.div variants={buttonVariants}>
            <Button
              isDisabled={!(email.length > 0 && password.length > 0)}
              color="primary"
              onPress={handleLogin}
              className="w-full bg-blue-600 hover:bg-blue-700 transition duration-300"
              size="lg"
            >
              Se Connecter
            </Button>
          </motion.div>
        </motion.div>
        <motion.p
          className="pt-6 text-gray-400 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.6 }}
        >
          Vous n'avez pas de compte?{" "}
          <Link to="/sign-up" className="text-blue-500 hover:underline">
            Créez-en un
          </Link>
        </motion.p>
      </motion.div>
    </div>
  );
};

export default Login;
