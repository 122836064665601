// SelectedItem.js

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { doc, getDoc, deleteDoc } from "@firebase/firestore";
import { db } from "../../firebase";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionItem,
  Button,
  Chip,
  Divider,
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import Images from "../../components/images/Images";
import { Header } from "./actions/Actions";
import EditIcon from "../../icons/EditIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import CloseIcon from "../../icons/CloseIcon";
import ArrowIcon from "../../icons/ArrowIcon";
import { useWallet } from "../../context/WalletContext";
import ExternalLinkIcon from "../../icons/ExternalLinkIcon"; // Adjust the path as needed

export default function SelectedItem(props) {
  const [data, setData] = useState();
  const [isExpanded, setIsExpanded] = useState(false);

  const fetch = async () => {
    try {
      const docRef = doc(db, "items", props.itemId);
      const docSnap = await getDoc(docRef);
      console.log(docSnap.data());
      setData({ ...docSnap.data(), id: props.itemId });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, [props]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleEdit = () => {
    searchParams.delete("item");
    searchParams.append("editItem", props.itemId);
    setSearchParams(searchParams);
  };

  const handleClose = () => {
    searchParams.delete("item");
    setSearchParams(searchParams);
  };

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleDelete = () => {
    onOpen();
  };

  const displayedKeys = ["brand", "condition", "size", "color", "category"];

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  // Image expand/collapse animation variants
  const imageVariants = {
    collapsed: { height: 200 },
    expanded: { height: "auto" },
  };

  // Import wallet data
  const { walletData } = useWallet();

  // Function to get the status message
  const getStatusMessage = () => {
    if (!data) return "";

    const status = data.status;

    if (status === "saved") {
      // Check if user has enough balance
      const requiredBalance = Number(data.buyTargetMax) + 10;
      if (walletData && walletData.balance < requiredBalance) {
        return "Vous n'avez pas assez de fonds pour commencer ce processus.";
      } else {
        return "Prêt à commencer le processus d'achat revente.";
      }
    } else if (status === "negotiating") {
      return "En cours de négociation.";
    } else if (status === "buyConfirm") {
      return "En attente de confirmation d'achat.";
    } else if (status === "receiving") {
      return "Réception en cours.";
    } else if (status === "handling") {
      return "Article en cours de traitement.";
    } else if (status === "selling") {
      return "Revente en cours.";
    } else if (status === "sellConfirm") {
      return "En attente de confirmation de vente.";
    } else if (status === "sold") {
      return "Article vendu.";
    } else if (status === "sent") {
      return "Article envoyé à l'acheteur.";
    } else if (status === "concluded") {
      return "Processus terminé.";
    } else {
      return "";
    }
  };

  // Toggle images expansion
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="px-2">
      {data && (
        <motion.div
          className="bg-gray-800 rounded-xl shadow-medium overflow-hidden"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Images Section */}
          <motion.div
            className="relative overflow-hidden"
            initial={false}
            animate={isExpanded ? "expanded" : "collapsed"}
            variants={imageVariants}
            transition={{ duration: 0.3 }}
          >
            {/* Images */}
            {data.images && (
              <div className="w-full" onClick={() => setIsExpanded(true)}>
                <Images
                  imageList={data.images}
                  showThumbnails
                  thumbnailsPosition="right"
                />
              </div>
            )}
            {/* Close Button */}
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 focus:outline-none bg-black bg-opacity-50 rounded-full p-1"
              aria-label="Fermer"
            >
              <CloseIcon size={28} color="#FFFFFF" />
            </button>
            {/* Expand/Collapse Button */}
            <button
              onClick={toggleExpand}
              className="absolute top-2 left-2 focus:outline-none bg-black bg-opacity-50 rounded-full p-1"
              aria-label={
                isExpanded ? "Réduire les images" : "Agrandir les images"
              }
            >
              <ArrowIcon
                size={28}
                color="#FFFFFF"
                direction={isExpanded ? "up" : "down"}
              />
            </button>
          </motion.div>

          {/* Content */}
          <div className="px-4 md:px-6 pb-6">
            {/* Vinted URL Link */}
            {data.vintedUrl && data.status == "saved" ? (
              <div className="mt-3 flex justify-between">
                <a
                  href={data.vintedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-500 flex items-center text-sm font-medium"
                >
                  Voir sur Vinted
                  <ExternalLinkIcon size={16} color="#3B82F6" />
                </a>
                <Chip color="primary">
                  {data.buyPrice ? data.buyPrice : data.price}€
                </Chip>
              </div>
            ) : (
              (data.status == "selling" ||
                data.status == "sold" ||
                data.status == "send") && (
                <a
                  href={data.vintedSellingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-3 text-blue-400 hover:text-blue-500 flex items-center text-sm font-medium"
                >
                  Voir sur Vinted
                  <ExternalLinkIcon size={16} color="#3B82F6" />
                </a>
              )
            )}
            <Accordion>
              <AccordionItem
                key="1"
                aria-label="Accordion 1"
                title={
                  <div className="w-full flex items-center justify-between">
                    <div className="flex gap-3">
                      <h1 className="text-2xl font-bold text-white">
                        {data.title}
                      </h1>
                      {data.status == "saved" && (
                        <button
                          onClick={handleEdit}
                          className="focus:outline-none"
                        >
                          <EditIcon size={24} color="#9CA3AF" />
                        </button>
                      )}
                    </div>
                    <div className="flex items-center justify-between pb-2 mt-2">
                      {data.status === "saved" && (
                        <button
                          onClick={handleDelete}
                          className="focus:outline-none"
                        >
                          <DeleteIcon size={26} color="#EF4444" />
                        </button>
                      )}
                    </div>
                  </div>
                }
              >
                {/* Description */}
                <p className="text-gray-300 mb-4">{data.description}</p>

                {/* Chips */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {displayedKeys.map((key) => (
                    <Chip key={key} className="bg-gray-700 text-white">
                      {key}: {data[key]?.toString()}
                    </Chip>
                  ))}
                </div>
              </AccordionItem>
            </Accordion>
            <Divider className="mb-4" />

            {/* Status Message */}
            <div className="mb-4">
              <span
                className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 font-bold text-lg animate-gradientMove bg-[200%_200%]"
                // Alternatively, use inline styles if arbitrary values aren't working
                style={{ backgroundSize: "200% 200%" }}
              >
                {getStatusMessage()}
              </span>
            </div>

            {/* Divider */}

            {/* Actions or Buying/Selling Related Info */}
            <Header fetch={fetch} itemId={props.itemId} data={data} />
          </div>
          {/* Confirmation Modal */}
          <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            className="!bg-gray-700 !text-white transition-opacity duration-300 ease-in-out"
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    <h2 className="text-xl font-bold">
                      Confirmation de Suppression
                    </h2>
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Êtes-vous sûr de vouloir supprimer cet article ? Cette
                      action est irréversible.
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      variant="light"
                      onPress={onClose}
                      className="mr-2"
                    >
                      Annuler
                    </Button>
                    <Button
                      color="primary"
                      onPress={async () => {
                        try {
                          await deleteDoc(doc(db, "items", props.itemId));
                          handleClose();
                        } catch (error) {
                          console.log(error);
                          // Optional: Implement user-facing error handling here
                        } finally {
                          onClose();
                        }
                      }}
                    >
                      Confirmer
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </motion.div>
      )}
    </div>
  );
}
