// ContactPage.jsx

import React from "react";
import { motion } from "framer-motion";
import { Card, CardBody } from "@nextui-org/react";

const contactInfo = [
  {
    title: "Téléphone",
    description: "+33 7 70 04 85 97",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12 text-green-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M3 5h2l3 5-2 2 2 2 5 3v2h-2c-4.97 0-9-4.03-9-9V5z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </svg>
    ),
  },
  {
    title: "Email",
    description: "mteonidis@gmail.com",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12 text-blue-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8m-18 8h18"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </svg>
    ),
  },
  {
    title: "Adresse",
    description: "31 Rue de la Marne, 78600 Le Mesnil-le-Roi, France",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12 text-red-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <circle cx="12" cy="9" r="2" />
      </svg>
    ),
  },
];

const teamMembers = [
  {
    name: "Alice Dupont",
    role: "CEO & Co-fondatrice",
    image: "https://source.unsplash.com/200x200/?woman,portrait", // Remplacez par vos images
  },
  {
    name: "Jean Martin",
    role: "CTO & Co-fondateur",
    image: "https://source.unsplash.com/200x200/?man,portrait",
  },
  // Ajoutez d'autres membres si nécessaire
];

const ContactPage = () => {
  // Variantes pour les animations Framer Motion
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2 },
    }),
  };

  return (
    <div className="font-sans">
      {/* Section Titre avec Gradient */}
      <div className="py-24 px-6 text-center bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-5xl md:text-6xl font-bold text-white mb-4"
        >
          Contactez-Nous
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-xl md:text-2xl text-gray-100"
        >
          Nous sommes là pour répondre à toutes vos questions
        </motion.p>
      </div>

      {/* Section Informations de Contact */}
      <div className="py-16 px-6 bg-gray-900 text-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12">
          {contactInfo.map((info, index) => (
            <motion.div
              key={index}
              custom={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInUp}
              className="flex flex-col items-center text-center bg-gray-800 rounded-lg p-8 transform hover:-translate-y-2 transition duration-300"
            >
              <div className="mb-4">{info.icon}</div>
              <h3 className="text-2xl font-semibold mb-2">{info.title}</h3>
              <p className="text-gray-300">{info.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Section Équipe */}

      {/* Section CTA */}
      <div className="py-24 px-6 bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-center">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-white">
          Prêt à Rejoindre l'Aventure ?
        </h2>
        <p className="text-2xl md:text-3xl mb-8 text-gray-100">
          Contactez-nous dès aujourd'hui pour commencer
        </p>
        <a href="/sign-up">
          <button className="px-8 py-4 bg-white text-blue-600 font-semibold text-lg rounded-md shadow-md transition transform hover:scale-105">
            Commencer Maintenant
          </button>
        </a>
      </div>
    </div>
  );
};

export default ContactPage;
