// WelcomePage.jsx

import React, { useContext } from "react";
import { Button } from "@nextui-org/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

// Video files
import importVideo from "./import.MP4";
import pricesVideo from "./prices.MP4";
import startVideo from "./start.MP4";
import profitVideo from "./profit.MP4";

import Logo from "../../icons/Logo";

const WelcomePage = () => {
  const { currentUser } = useContext(AuthContext);

  // Framer motion variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 40 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const fadeInDelayed = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1, delay: 0.5 } },
  };

  const staggerContainer = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  // A subtle floating animation for any decorative element
  const floatAnimation = {
    hidden: { y: 0 },
    visible: {
      y: [0, -10, 0],
      transition: { duration: 3, repeat: Infinity, ease: "easeInOut" },
    },
  };

  return (
    <div className="bg-gray-900 text-white font-sans mt-[-60px]">
      {/* Hero Section */}
      <div className="relative min-h-screen flex flex-col items-center justify-center overflow-hidden">
        {/* Background gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-blue-800 via-purple-800 to-gray-900 opacity-90"></div>

        <motion.div
          className="relative z-10 text-center max-w-3xl px-6"
          variants={staggerContainer}
          initial="hidden"
          animate="visible"
        >
          <motion.h1
            className="text-4xl md:text-6xl font-bold leading-tight mb-4"
            variants={fadeInUp}
          >
            Revendez sur Vinted sans jamais toucher d'articles.
          </motion.h1>
          <motion.p
            className="text-lg md:text-2xl text-gray-300 mb-8"
            variants={fadeInUp}
          >
            Gagnez du temps, laissez nous automatiser l'achat / revente et
            profitez de bénéfices.
          </motion.p>
          <motion.div variants={fadeInUp}>
            {currentUser ? (
              <Link to="/home">
                <Button
                  size="lg"
                  className="bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:scale-105 transition transform"
                >
                  Accéder à l'application
                </Button>
              </Link>
            ) : (
              <div className="flex flex-col md:flex-row gap-4 justify-center">
                <Link to="/sign-up">
                  <Button
                    size="lg"
                    className="bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:scale-105 transition transform"
                  >
                    Commencer Maintenant
                  </Button>
                </Link>
                <Link to="/login">
                  <Button
                    size="lg"
                    bordered
                    variant="bordered"
                    color="primary"
                    className="hover:scale-105 transition transform"
                  >
                    Se Connecter
                  </Button>
                </Link>
              </div>
            )}
          </motion.div>
          <motion.div className="mt-16 flex justify-center" variants={fadeInUp}>
            <a href="#steps">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-white animate-bounce"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </a>
          </motion.div>
        </motion.div>
      </div>

      {/* Steps Section */}
      <div
        id="steps"
        className="bg-gray-900 flex flex-col items-center justify-center"
      >
        {/* A subtle header for steps */}
        <motion.div
          className="text-center my-16 px-6 max-w-3xl"
          variants={staggerContainer}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <motion.h2
            className="text-3xl md:text-4xl font-bold mb-4"
            variants={fadeInUp}
          >
            Comment ça marche ?
          </motion.h2>
          <motion.p
            className="text-gray-300 text-lg md:text-xl"
            variants={fadeInUp}
          >
            Suivez simplement ces étapes pour démarrer et profiter de la
            simplicité de notre plateforme.
          </motion.p>
        </motion.div>

        {/* Step Cards */}
        <div className="space-y-32 w-full max-w-6xl px-6 mb-8">
          {/* Step 1 */}
          <motion.div
            className="flex flex-col md:flex-row items-center gap-8"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.div className="md:w-1/2" variants={fadeInUp}>
              <h3 className="text-2xl md:text-3xl font-bold mb-4">
                1. Importez un article depuis Vinted
              </h3>
              <p className="text-gray-300 text-lg mb-4">
                Trouvez l'article idéal sur Vinted, copiez le lien et collez-le
                dans notre application. C'est aussi simple que ça.
              </p>
            </motion.div>
            <motion.div
              className="md:w-1/2 flex justify-center"
              variants={fadeInUp}
            >
              <div className="relative w-64 md:w-72 h-[400px] border-8 border-gray-800 rounded-3xl bg-black overflow-hidden shadow-lg">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-2 w-20 h-5 bg-gray-800 rounded-b-xl"></div>
                <video
                  src={importVideo}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-full h-full object-cover"
                />
              </div>
            </motion.div>
          </motion.div>

          {/* Step 2 */}
          <motion.div
            className="flex flex-col md:flex-row items-center gap-8"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.div
              className="md:w-1/2 order-2 md:order-1 flex justify-center"
              variants={fadeInUp}
            >
              <div className="relative w-64 md:w-72 h-[400px] border-8 border-gray-800 rounded-3xl bg-black overflow-hidden shadow-lg">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-2 w-20 h-5 bg-gray-800 rounded-b-xl"></div>
                <video
                  src={pricesVideo}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-full h-full object-cover"
                />
              </div>
            </motion.div>
            <motion.div
              className="md:w-1/2 order-1 md:order-2"
              variants={fadeInUp}
            >
              <h3 className="text-2xl md:text-3xl font-bold mb-4">
                2. Définissez vos prix
              </h3>
              <p className="text-gray-300 text-lg mb-4">
                Indiquez le prix d'achat maximum et votre prix de revente
                souhaité. Notre équipe utilise ces informations pour maximiser
                vos profits.
              </p>
            </motion.div>
          </motion.div>

          {/* Step 3 */}
          <motion.div
            className="flex flex-col md:flex-row items-center gap-8"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.div className="md:w-1/2" variants={fadeInUp}>
              <h3 className="text-2xl md:text-3xl font-bold mb-4">
                3. Laissez-nous travailler
              </h3>
              <p className="text-gray-300 text-lg mb-4">
                Nous négocions, achetons, stockons, et préparons une annonce
                professionnelle, avec de belles photos. Vous n'avez rien à
                faire, nous gérons tout.
              </p>
            </motion.div>
            <motion.div
              className="md:w-1/2 flex justify-center"
              variants={fadeInUp}
            >
              <div className="relative w-64 md:w-72 h-[400px] border-8 border-gray-800 rounded-3xl bg-black overflow-hidden shadow-lg">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-2 w-20 h-5 bg-gray-800 rounded-b-xl"></div>
                <video
                  src={startVideo}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-full h-full object-cover"
                />
              </div>
            </motion.div>
          </motion.div>

          {/* Step 4 */}
          <motion.div
            className="flex flex-col md:flex-row items-center gap-8"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.div
              className="md:w-1/2 order-2 md:order-1 flex justify-center"
              variants={fadeInUp}
            >
              <div className="relative w-64 md:w-72 h-[400px] border-8 border-gray-800 rounded-3xl bg-black overflow-hidden shadow-lg">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-2 w-20 h-5 bg-gray-800 rounded-b-xl"></div>
                <video
                  src={profitVideo}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-full h-full object-cover"
                />
              </div>
            </motion.div>
            <motion.div
              className="md:w-1/2 order-1 md:order-2"
              variants={fadeInUp}
            >
              <h3 className="text-2xl md:text-3xl font-bold mb-4">
                4. Recevez vos gains
              </h3>
              <p className="text-gray-300 text-lg mb-4">
                Une fois l'article vendu, votre profit est crédité sur votre
                compte. Retirez vos fonds à tout moment, en toute simplicité.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="relative py-24 px-6 text-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 blur-2xl opacity-20"></div>
        <motion.div
          className="relative z-10 max-w-3xl mx-auto"
          variants={staggerContainer}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <motion.h2
            className="text-3xl md:text-4xl font-bold mb-6"
            variants={fadeInUp}
          >
            Prêt à démarrer votre parcours ?
          </motion.h2>
          <motion.p
            className="text-xl md:text-2xl text-gray-200 mb-8"
            variants={fadeInUp}
          >
            Rejoignez-nous dès aujourd'hui et simplifiez votre revente sur
            Vinted.
          </motion.p>
          <motion.div variants={fadeInUp}>
            {currentUser ? (
              <Link to="/home">
                <Button
                  size="lg"
                  color="white"
                  className="bg-white text-blue-600 hover:scale-105 transition transform"
                >
                  Accéder à l'application
                </Button>
              </Link>
            ) : (
              <Link to="/sign-up">
                <Button
                  size="lg"
                  color="white"
                  className="bg-white text-blue-600 hover:scale-105 transition transform"
                >
                  S'inscrire Gratuitement
                </Button>
              </Link>
            )}
          </motion.div>
        </motion.div>
      </div>

      {/* Footer */}
      <div className="py-8 px-6 bg-gray-900 text-center border-t border-gray-700">
        <p className="text-gray-500">
          &copy; {new Date().getFullYear()} FT-R.org. Tous droits réservés.
        </p>
      </div>
    </div>
  );
};

export default WelcomePage;
