// WelcomeAnimation.jsx

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const WelcomeAnimation = ({ onComplete }) => {
  const controls = useAnimation();

  useEffect(() => {
    // Sequence: Entrance -> Wait -> Exit
    const sequence = async () => {
      // Start Entrance Animation
      await controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 1, ease: "easeOut" },
      });

      // Wait for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Start Exit Animation
      await controls.start({
        opacity: 0,
        scale: 1,
        transition: { duration: 1, ease: "easeIn" },
      });

      // Notify parent that animation is complete
      if (onComplete) {
        onComplete();
      }
    };

    sequence();
  }, [controls, onComplete]);

  return (
    <motion.div
      className="fixed inset-0 bg-gradient-to-br from-indigo-900 via-purple-900 to-pink-900 flex items-center justify-center z-50 overflow-hidden"
      initial={{ opacity: 0, scale: 1 }}
      animate={controls}
    >
      {/* Background Circles */}
      <div className="absolute w-full h-full">
        <motion.div
          className="absolute bg-gradient-to-r from-blue-500 to-purple-500 opacity-20 rounded-full"
          initial={{ scale: 0 }}
          animate={{ scale: 3 }}
          transition={{ duration: 5, repeat: Infinity, repeatType: "reverse" }}
          style={{ width: "30%", height: "30%", top: "10%", left: "15%" }}
        />
        <motion.div
          className="absolute bg-gradient-to-r from-pink-500 to-red-500 opacity-20 rounded-full"
          initial={{ scale: 0 }}
          animate={{ scale: 2 }}
          transition={{ duration: 6, repeat: Infinity, repeatType: "reverse" }}
          style={{ width: "25%", height: "25%", bottom: "15%", right: "20%" }}
        />
      </div>

      <motion.div
        className="text-center text-white relative z-10"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <motion.h1
          className="text-6xl md:text-8xl font-extrabold mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
        >
          Bienvenue!
        </motion.h1>
        <motion.p
          className="text-2xl md:text-4xl font-semibold"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8, ease: "easeOut" }}
        >
          On vous attendait
        </motion.p>
      </motion.div>
    </motion.div>
  );
};

export default WelcomeAnimation;
