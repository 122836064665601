// SignupPage.jsx

import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, Chip, Spinner } from "@nextui-org/react";
import { motion, AnimatePresence } from "framer-motion"; // Import AnimatePresence
import { AuthContext } from "../../context/AuthContext";
import { auth, RecaptchaVerifier } from "../../firebase";
import {
  signInWithPhoneNumber,
  EmailAuthProvider,
  linkWithCredential,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import VerificationInput from "react-verification-input";
import "./PhoneNumber.css";
import CustomPhoneInput from "./CustomPhoneInput";
import { getCountryCallingCode } from "libphonenumber-js";
import CustomVerificationInput from "./CustomVerificationInput";
import WelcomeAnimation from "./WelcomeAnimation";

// Custom SVG Icons
const EyeIcon = (props) => (
  <svg
    {...props}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    className="text-2xl text-gray-400"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8S1 12 1 12z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <circle
      cx={12}
      cy={12}
      r={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

const EyeSlashIcon = (props) => (
  <svg
    {...props}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    className="text-2xl text-gray-400"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.875 18.825A10.05 10.05 0 0112 19c-7 0-11-7-11-7a21.607 21.607 0 014.277-5.415M3 3l18 18"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      d="M17.657 17.657L6.343 6.343M10.828 13.172a4 4 0 005.656-5.656"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

const PhoneIcon = (props) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 20 20"
    className="text-2xl text-gray-400"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M2.003 5.884l2.386-.478a1 1 0 01.947.316l1.577 1.812a1 1 0 01-.1 1.38l-1.1 1a9.004 9.004 0 004.486 4.486l1-1.1a1 1 0 011.38-.1l1.812 1.577a1 1 0 01.316.947l-.478 2.386a1 1 0 01-.98.815C5.08 19 1 14.92 1 10.003a1 1 0 011.003-.981z"
      clipRule="evenodd"
    />
  </svg>
);

const SignupPage = () => {
  // State variables
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("fr"); // Default country code
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmResult, setConfirmResult] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    phone: null,
    email: null,
    password: [],
  });
  const [isValid, setIsValid] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSolved, setIsSolved] = useState(false);
  const [verifyError, setVerifyError] = useState();
  const [showWelcomeAnimation, setShowWelcomeAnimation] = useState(false); // New state for WelcomeAnimation

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  // Initialize reCAPTCHA
  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,

      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          setIsSolved(true);
          setLoading(false);
        },
      }
    );
  }, []);

  // Validation functions
  const validateInput = (field) => {
    const newErrors = { ...errors };

    if (!field || field === "phone") {
      const fullPhoneNumber = `+${getCountryCallingCode(
        countryCode.toUpperCase()
      )}${phoneNumber.replace(/\D/g, "")}`;
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      newErrors.phone = phoneRegex.test(fullPhoneNumber)
        ? null
        : "Format du numéro de téléphone invalide.";
    }

    if (!field || field === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      newErrors.email = emailRegex.test(email)
        ? null
        : "Format de l'adresse email invalide.";
    }

    if (!field || field === "password") {
      const passwordErrors = [];
      if (password.length < 6 || password.length > 24) {
        passwordErrors.push(
          "Le mot de passe doit contenir entre 6 et 24 caractères."
        );
      }
      if (!/[A-Z]/.test(password)) {
        passwordErrors.push(
          "Le mot de passe doit contenir au moins une lettre majuscule."
        );
      }
      if (!/[a-z]/.test(password)) {
        passwordErrors.push(
          "Le mot de passe doit contenir au moins une lettre minuscule."
        );
      }
      if (!/[0-9]/.test(password)) {
        passwordErrors.push(
          "Le mot de passe doit contenir au moins un chiffre."
        );
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        passwordErrors.push(
          "Le mot de passe doit contenir au moins un caractère spécial."
        );
      }
      newErrors.password = passwordErrors.length ? passwordErrors : null;
    }

    if (field === "zero") {
      setErrors({
        phone: null,
        email: null,
        password: [],
      });
    } else {
      setErrors(newErrors);
    }

    setIsValid(
      !newErrors.phone &&
        !newErrors.email &&
        (!newErrors.password || newErrors.password.length === 0)
    );
  };

  useEffect(() => validateInput("phone"), [phoneNumber]);
  useEffect(() => validateInput("email"), [email]);
  useEffect(() => validateInput("password"), [password]);
  useEffect(() => validateInput("zero"), []);

  const checkEmailInUse = async () => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        setErrors({ ...errors, email: "L'adresse email est déjà utilisée." });
        return false;
      }
      return true;
    } catch (error) {
      console.error("Erreur lors de la vérification de l'email:", error);
      setErrors({ ...errors, email: "Erreur de vérification de l'email." });
      return false;
    }
  };

  const functions = getFunctions();

  const checkPhoneNumberInUse = async () => {
    const checkPhoneNumber = httpsCallable(functions, "checkPhoneNumberInUse");
    const fullPhoneNumber = `+${getCountryCallingCode(
      countryCode.toUpperCase()
    )}${phoneNumber.replace(/\D/g, "")}`;

    try {
      const result = await checkPhoneNumber({
        phoneNumber: fullPhoneNumber,
      });
      return result.data.exists;
    } catch (error) {
      console.error("Erreur lors de la vérification du numéro:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Erreur lors de la vérification du numéro.",
      }));
      return true; // Avoid proceeding if an error occurs
    }
  };

  const handleFirebaseError = (error) => {
    const errorMessages = {
      "auth/invalid-phone-number": "Numéro de téléphone invalide.",
      "auth/missing-phone-number": "Le numéro de téléphone est requis.",
      "auth/too-many-requests":
        "Trop de tentatives. Veuillez réessayer plus tard.",
      "auth/quota-exceeded": "Quota d'envoi de SMS dépassé.",
      "auth/network-request-failed":
        "Problème de réseau. Veuillez vérifier votre connexion.",
      "auth/invalid-verification-code": "Code de vérification invalide.",
      "auth/code-expired":
        "Le code de vérification a expiré. Veuillez renvoyer le code.",
      default: "Une erreur s'est produite. Veuillez réessayer.",
    };

    return errorMessages[error.code] || errorMessages.default;
  };

  const handleSendCode = async () => {
    setLoading(true);
    if (!isValid) return;

    // Check if email is available
    const isEmailAvailable = await checkEmailInUse();
    if (!isEmailAvailable) {
      setLoading(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Cette adresse email est déjà utilisée.",
      }));
      return;
    }

    // Check if phone number is in use
    const isPhoneUsed = await checkPhoneNumberInUse();
    if (isPhoneUsed) {
      setLoading(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Ce numéro de téléphone est déjà utilisé.",
      }));
      return;
    }

    const appVerifier = window.recaptchaVerifier;
    const fullPhoneNumber = `+${getCountryCallingCode(
      countryCode.toUpperCase()
    )}${phoneNumber.replace(/\D/g, "")}`;

    try {
      // Send verification code
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        fullPhoneNumber,
        appVerifier
      );
      setConfirmResult(confirmationResult);
      setLoading(false);
      setIsCodeSent(true);
    } catch (error) {
      setLoading(false);
      const errorMessage = handleFirebaseError(error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: errorMessage,
      }));
    }
  };

  const handleFirebaseError2 = (error) => {
    const errorMessages = {
      "auth/invalid-verification-code": "Le code de vérification est invalide.",
      "auth/code-expired":
        "Le code de vérification a expiré. Veuillez demander un nouveau code.",
      "auth/too-many-requests":
        "Trop de tentatives. Veuillez réessayer plus tard.",
      "auth/network-request-failed":
        "Problème de réseau. Veuillez vérifier votre connexion.",
      default: "Une erreur s'est produite. Veuillez réessayer.",
    };

    return errorMessages[error.code] || errorMessages.default;
  };

  const handleVerifyCode = async () => {
    setLoading(true);
    if (!confirmResult) return;

    try {
      // Try to confirm the verification code
      await confirmResult.confirm(verificationCode);
      await handleLinkEmailPassword();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = handleFirebaseError2(error);
      console.error("Erreur lors de la vérification du code:", error);
      setVerifyError(errorMessage); // Show the error message in French
    }
  };

  const handleLinkEmailPassword = async () => {
    const user = auth.currentUser;
    if (!user) return alert("Utilisateur non connecté!");

    try {
      const credential = EmailAuthProvider.credential(email, password);
      await linkWithCredential(user, credential);
      await sendEmailVerification(user);
      dispatch({ type: "LOGIN", payload: user });
      setShowWelcomeAnimation(true); // Show WelcomeAnimation
      // Wait for 3 seconds before navigating to /home
      setTimeout(() => {
        navigate("/home");
      }, 4000);
    } catch (error) {
      console.error("Erreur lors de la liaison email et mot de passe:", error);
      alert(error.message);
    }
  };

  // Toggle password visibility
  const toggleVisibility = () => setIsVisible(!isVisible);

  // Framer Motion variants
  const containerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const inputVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: 0.3 + i * 0.1, duration: 0.6, ease: "easeOut" },
    }),
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.8, duration: 0.6, ease: "easeOut" },
    },
  };

  return (
    <div className="mt-[70px] flex items-center justify-center bg-gray-900 text-white px-5">
      <AnimatePresence
        initial={false}
        onExitComplete={() => {
          if (showWelcomeAnimation) {
            navigate("/home"); // Navigate after the exit animation completes
          }
        }}
      >
        {showWelcomeAnimation && <WelcomeAnimation />}
      </AnimatePresence>
      <motion.div
        className="w-full max-w-md p-8 bg-gray-800 rounded-lg shadow-lg"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h2
          className="text-3xl font-bold mb-6 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
        >
          S'inscrire
        </motion.h2>
        {!isCodeSent ? (
          <motion.div className="space-y-5" initial="hidden" animate="visible">
            {/* Phone Input */}
            <motion.div custom={0} variants={inputVariants}>
              <CustomPhoneInput
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                countryCode={countryCode}
                onCountryChange={(code) => setCountryCode(code)}
                error={errors.phone}
              />
            </motion.div>
            {/* Email Input */}
            <motion.div custom={1} variants={inputVariants}>
              <Input
                size="lg"
                variant="underlined"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                label="Email"
                placeholder="Entrez votre email"
                className="w-full"
              />
              {errors.email && (
                <Chip className="mt-2" size="sm" color="danger">
                  {errors.email}
                </Chip>
              )}
            </motion.div>
            {/* Password Input */}
            <motion.div custom={2} variants={inputVariants}>
              <Input
                size="lg"
                variant="underlined"
                label="Mot de passe"
                placeholder="Entrez votre mot de passe"
                onChange={(e) => setPassword(e.target.value)}
                endContent={
                  <button
                    onClick={toggleVisibility}
                    aria-label="toggle password visibility"
                    className="focus:outline-none"
                  >
                    {isVisible ? <EyeSlashIcon /> : <EyeIcon />}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                className="w-full"
                isClearable
              />
              {errors.password && errors.password.length > 0 && (
                <div className="mt-2 space-y-1">
                  {errors.password.map((error, index) => (
                    <Chip key={index} size="sm" color="danger">
                      {error}
                    </Chip>
                  ))}
                </div>
              )}
            </motion.div>
            {/* Send Code Button */}
            <motion.div variants={buttonVariants}>
              <Button
                isDisabled={
                  !(
                    isValid &&
                    email.length > 0 &&
                    phoneNumber.length > 0 &&
                    password.length > 0
                  )
                }
                onPress={handleSendCode}
                id="sign-in-button"
                className="w-full bg-blue-600 hover:bg-blue-700 transition duration-300"
                size="lg"
              >
                {loading ? <Spinner color="white" /> : "S'inscrire"}
              </Button>
            </motion.div>
          </motion.div>
        ) : (
          <motion.div
            className="space-y-5"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <p className="text-center text-gray-400">
              Un code vous a été envoyé au +{phoneNumber}.{" "}
              <span
                className="hover:cursor-pointer hover:underline"
                onClick={() => setIsCodeSent(false)}
              >
                Numéro de téléphone incorrect?
              </span>
            </p>
            {verifyError && (
              <Chip color="danger" size="sm">
                {verifyError}
              </Chip>
            )}
            {/* Verification Code Input */}
            <CustomVerificationInput
              length={6}
              onComplete={(code) => setVerificationCode(code)}
            />
            {/* Verify Code Button */}
            <Button
              isDisabled={
                !(
                  isValid &&
                  email.length > 0 &&
                  phoneNumber.length > 0 &&
                  password.length > 0 &&
                  verificationCode.length === 6
                )
              }
              onPress={handleVerifyCode}
              className="w-full bg-blue-600 hover:bg-blue-700 transition duration-300"
              size="lg"
            >
              {loading ? <Spinner color="white" /> : "Vérifier le code"}
            </Button>
          </motion.div>
        )}
        {!isCodeSent && (
          <motion.p
            className="pt-6 text-gray-400 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.6 }}
          >
            Vous possédez déjà un compte?{" "}
            <Link to="/login" className="text-blue-500 hover:underline">
              Connectez-vous
            </Link>
          </motion.p>
        )}
      </motion.div>
    </div>
  );
};

export default SignupPage;
