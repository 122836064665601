const RankingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path fillOpacity="0.01" d="M0 0h48v48H0z"></path>
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M4 18h13v24H4z"
    ></path>
    <path
      stroke={props.color}
      strokeLinejoin="round"
      strokeWidth="4"
      d="M17 6h13v36H17z"
    ></path>
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M30 26h13v16H30z"
    ></path>
  </svg>
);

export default RankingIcon;
