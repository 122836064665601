// HandlingIcon.jsx

import React from "react";

const HandlingIcon = ({ size = 24, color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Capa_1"
    width={size}
    height={size}
    fill={color}
    version="1.1"
    viewBox="0 0 449.505 449.505"
  >
    <path d="M306.079 223.021c-.632-7.999-7.672-14.605-15.694-14.728l-53.093-.814c-3.084-.047-6.21-2.762-6.689-5.809l-11.698-74.37c-.424-2.694-2.936-13.678-16.649-13.678l-66.024 2.875c-8.698.378-15.769 4.607-15.769 16.476 0 0-.278 165.299-.616 171.289l-2.31 40.898c-.309 5.462-2.437 14.303-4.647 19.306l-26.724 60.487c-1.764 3.991-1.735 8.403.08 12.105s5.284 6.428 9.52 7.48l8.897 2.208c1.324.329 2.71.495 4.118.495 7.182 0 14.052-4.168 17.096-10.372l25.403-51.78c2.806-5.719 6.298-15.412 7.786-21.607l14.334-59.711 34.689 53.049c2.86 4.374 5.884 12.767 6.471 17.961l6.706 59.392c.954 8.454 8.654 15.332 17.164 15.332l10.146-.035c4.353-.015 8.311-1.752 11.145-4.893s4.158-7.254 3.728-11.585l-7.004-70.612c-.646-6.512-2.985-16.401-5.325-22.513l-31.083-81.187-.192-17.115 72.241-2.674c4.033-.149 7.718-1.876 10.376-4.862 2.658-2.985 3.947-6.845 3.629-10.873zM238.43 444.503v.002zM157.338 97.927c5.558 0 11.054-.948 16.335-2.819 12.327-4.362 22.216-13.264 27.846-25.066 3.981-8.345 5.483-17.433 4.486-26.398l16.406-1.851c5.717-.645 11.52-5.205 13.498-10.607l5.495-15.007c1.173-3.206.864-6.45-.849-8.902-1.67-2.39-4.484-3.761-7.72-3.761q-.563 0-1.161.056l-47.438 4.512C176.416 2.933 167.116 0 157.333 0c-5.556 0-11.05.947-16.333 2.816-12.326 4.365-22.215 13.268-27.846 25.07s-6.328 25.089-1.963 37.413c6.911 19.516 25.456 32.628 46.147 32.628"></path>
    <path d="m364.605 174.546-4.72-67.843c-.561-8.057-7.587-14.611-15.691-14.611l-90.689.158c-4.06.007-7.792 1.618-10.509 4.536-2.716 2.917-4.058 6.754-3.775 10.805l4.72 67.843c.561 8.057 7.587 14.611 15.664 14.611l90.716-.158c4.06-.007 7.792-1.617 10.509-4.535 2.716-2.918 4.057-6.756 3.775-10.806m-105.001 10.498"></path>
  </svg>
);

export default HandlingIcon;
