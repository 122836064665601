// ArrowIcon.js

import React from "react";

function ArrowIcon({ size = 24, color = "currentColor", direction = "down" }) {
  const svgPaths = {
    down: <path d="M12 15l-6-6h12l-6 6z" fill={color} />,
    up: <path d="M12 9l6 6H6l6-6z" fill={color} />,
  };

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {svgPaths[direction]}
    </svg>
  );
}

export default ArrowIcon;
