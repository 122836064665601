// ResaleIcon.jsx

import React from "react";

const ResaleIcon = ({ size = 24, color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width={size}
    height={size}
    fill={color}
    version="1.1"
    viewBox="0 0 256 253"
  >
    <path d="M127.925 218.85c28.065 0 51-22.784 51-51s-22.784-51-51-51-50.849 22.935-50.849 51.151c-.001 28.065 22.783 50.849 50.849 50.849m-2.817-46.674c-9.355-3.269-15.189-7.997-15.189-16.396 0-7.746 5.13-13.831 14.536-15.743v-8.399h7.041v7.997c5.834 0 10.059 1.207 13.178 2.867l-2.867 10.059c-2.314-.956-6.086-2.565-11.266-2.565s-7.997 2.565-7.997 5.13c0 3.521 3.269 4.879 10.311 7.746 9.607 3.521 14.083 8.399 14.083 16.396s-4.879 14.083-15.189 16.396v7.997h-7.041v-7.746c-6.086 0-12.222-1.609-15.189-3.269l2.565-10.311c3.269 1.609 8.399 3.521 13.831 3.521 5.834 0 8.701-2.565 8.701-6.086s-2.768-4.979-9.508-7.594M2 69c0 13.678 9.625 25.302 22 29.576V233H2v18h252v-18h-22V98.554c12.89-3.945 21.699-15.396 22-29.554v-8H2zm63.29-.654c0 6.477 6.755 31.47 31.727 31.47 21.689 0 31.202-19.615 31.202-31.47 0 11.052 7.41 31.447 31.464 31.447 21.733 0 31.363-20.999 31.363-31.447 0 14.425 9.726 26.416 22.954 30.154V233H42V98.594c13.402-3.628 23.29-15.699 23.29-30.248M254 54H2l32-32V2h189v20h-.168z"></path>
  </svg>
);

export default ResaleIcon;
