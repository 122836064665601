// ExclusiveOfferPage.jsx

import React from "react";
import { motion } from "framer-motion";
import { Button } from "@nextui-org/react";
import { Link } from "react-router-dom";

const ExclusiveOfferPage = () => {
  return (
    <div className="bg-gradient-to-br from-purple-600 to-blue-500 text-white">
      <div className="py-24 px-6 text-center">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-5xl md:text-6xl font-bold mb-6"
        >
          Offre Exclusive
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto text-xl md:text-2xl mb-12"
        >
          Démarrez avec un avantage exceptionnel ! Nous vous offrons{" "}
          <span className="font-bold">5 pièces Carhartt</span> d'une sélection
          exclusive. Vendez-les sans frais et gardez 100% des bénéfices pour
          investir dans d'autres articles.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          className="mt-8"
        >
          <Link to="/sign-up">
            <Button
              size="lg"
              className="bg-white text-blue-600 font-semibold hover:bg-gray-200 transition duration-300"
            >
              Profiter de l'Offre Maintenant
            </Button>
          </Link>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8"
        >
          {/* Replace with actual images */}
          <img
            src="https://via.placeholder.com/300"
            alt="Carhartt Item 1"
            className="rounded-lg shadow-lg"
          />
          <img
            src="https://via.placeholder.com/300"
            alt="Carhartt Item 2"
            className="rounded-lg shadow-lg"
          />
          <img
            src="https://via.placeholder.com/300"
            alt="Carhartt Item 3"
            className="rounded-lg shadow-lg"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default ExclusiveOfferPage;
