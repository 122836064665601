// src/components/actions/Actions.js

import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  Divider,
  Modal,
  useDisclosure,
  Spinner,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@nextui-org/react";
import { useSearchParams } from "react-router-dom";
import { doc, deleteDoc, updateDoc } from "@firebase/firestore";
import { db } from "../../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { motion } from "framer-motion";
import Images from "../../../components/images/Images";
import EditIcon from "../../../icons/EditIcon";
import TickIcon from "../../../icons/TickIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import PurchaseIcon from "../../../icons/PurchaseIcon";
import HandlingIcon from "../../../icons/HandlingIcon";
import ResaleIcon from "../../../icons/ResaleIcon";
import ProfitIcon from "../../../icons/ProfitIcon";
import { useWallet } from "../../../context/WalletContext";
import { toast } from "react-toastify"; // Importing toast
import CloseIcon from "../../../icons/CloseIcon";
import ArrowIcon from "../../../icons/ArrowIcon";
import DangerIcon from "../../../icons/DangerIcon";
import { useMotionValue, useTransform, animate } from "framer-motion";
import Confetti from "react-confetti";

export const Header = (props) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isSelling, setIsSelling] = useState(props.data.status !== "saved");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const functions = getFunctions();
  const [error, setError] = useState(null);
  const { walletData } = useWallet();

  useEffect(() => {
    setIsSelling(props.data.status !== "saved");
  }, [props.data]);

  const handleEdit = () => {
    searchParams.delete("item");
    searchParams.append("editItem", props.itemId);
    setSearchParams(searchParams);
  };

  const handleStartProcess = async () => {
    setError(null);
    setIsLoading(true);
    const startProcess = httpsCallable(functions, "startProcess");

    try {
      await startProcess({ itemId: props.itemId });
      setIsLoading(false);
      props.fetch();
      setIsSelling(true);
      onOpenChange(false);
      toast.success("Processus démarré avec succès !");
    } catch (error) {
      setIsLoading(false);
      setError(error.message || "Une erreur est survenue.");
      console.error("Erreur lors du démarrage du processus:", error);
      toast.error("Erreur lors du démarrage du processus.");
    }
  };

  // Steps for the progress bar
  const steps = [
    {
      title: "Achat",
      icon: <PurchaseIcon size={24} color="white" />,
      status: ["negotiating", "buyConfirm", "buying"],
    },
    {
      title: "Gestion",
      icon: <HandlingIcon size={24} color="white" />,
      status: ["receiving", "handling"],
    },
    {
      title: "Revente",
      icon: <ResaleIcon size={24} color="white" />,
      status: ["selling", "sellConfirm", "sold", "sent"],
    },
    {
      title: "Bénéfices",
      icon: <ProfitIcon size={24} color="white" />,
      status: ["concluded"],
    },
  ];

  // Determine current step
  const currentStepIndex = steps.findIndex((step) =>
    step.status.includes(props.data.status)
  );

  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle images expansion
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Image expand/collapse animation variants
  const imageVariants = {
    collapsed: { height: 200 },
    expanded: { height: "auto" },
  };

  return (
    <div className="w-full">
      {/* Price Information */}
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-3 mb-6">
        {/* Purchase Price */}
        <div className="bg-gray-700 rounded-xl p-4 flex items-center justify-between">
          <div className="w-full">
            <span className="text-gray-400">
              {props.data.buyPrice ? "Prix d'achat" : "Objectif d'achat"}
            </span>
            <div className="w-full text-2xl font-bold text-white flex gap-2">
              <span>
                {props.data.buyPrice ? (
                  <>{props.data.buyPrice}€</>
                ) : (
                  <>
                    {props.data.buyTargetMin}-{props.data.buyTargetMax}€
                  </>
                )}
              </span>
              <span className="text-gray-500">+10€</span>
            </div>
          </div>
          {props.data.status === "saved" && (
            <button onClick={handleEdit} className="focus:outline-none">
              <EditIcon size={20} color="#9CA3AF" />
            </button>
          )}
        </div>

        {/* Sell Price */}
        <div className="bg-gray-700 rounded-xl p-4 flex items-center justify-between">
          <div>
            <span className="text-gray-400">
              {props.data.sellPrice ? "Prix de vente" : "Objectif de vente"}
            </span>
            <div className="text-2xl font-bold text-white">
              {props.data.sellPrice ? (
                <>{props.data.sellPrice}€</>
              ) : (
                <>
                  {props.data.sellTargetMin}-{props.data.sellTargetMax}€
                </>
              )}
            </div>
          </div>
          {props.data.status === "saved" && (
            <button onClick={handleEdit} className="focus:outline-none">
              <EditIcon size={20} color="#9CA3AF" />
            </button>
          )}
        </div>

        {/* Profit */}
        <div className="bg-gray-700 rounded-xl p-4">
          <span className="text-gray-400">Bénéfices</span>
          <div className="text-2xl font-bold text-green-500">
            {props.data.profit ? (
              <>{props.data.profit}€</>
            ) : props.data.sellPrice ? (
              <>{props.data.sellPrice - props.data.buyPrice - 10}€</>
            ) : props.data.buyPrice ? (
              <>
                {props.data.sellTargetMin - props.data.buyPrice - 10}-
                {props.data.sellTargetMax - props.data.buyPrice - 10}€
              </>
            ) : (
              <>
                {props.data.sellTargetMin - props.data.buyTargetMax - 10}-
                {props.data.sellTargetMax - props.data.buyTargetMin - 10}€
              </>
            )}
          </div>
        </div>
      </div>

      {/* Progress Section */}
      {isSelling ? (
        <div className="flex flex-col w-full">
          {/* Steps Progress Bar */}
          <div className="flex items-center justify-between mb-6">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`flex-1 flex flex-col items-center ${
                  index < steps.length - 1 ? "border-r-2 border-gray-600" : ""
                } px-2`}
              >
                <div
                  className={`w-12 h-12 flex items-center justify-center rounded-full ${
                    index <= currentStepIndex ? "bg-blue-600" : "bg-gray-600"
                  } mb-2`}
                >
                  {step.icon}
                </div>
                <span
                  className={`text-center text-sm ${
                    index <= currentStepIndex ? "text-white" : "text-gray-500"
                  }`}
                >
                  {step.title}
                </span>
              </div>
            ))}
          </div>

          {/* Step Content */}
          <motion.div
            className="bg-gray-700 rounded-xl"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            key={currentStepIndex}
          >
            {/* Render content based on current step */}
            {currentStepIndex === 0 && (
              // Purchase Step Content
              <PurchaseStepContent data={props.data} />
            )}
            {currentStepIndex === 1 && (
              // Handling Step Content
              <HandlingStepContent data={props.data} />
            )}
            {currentStepIndex === 2 && (
              // Resale Step Content
              <ResaleStepContent data={props.data} />
            )}
            {currentStepIndex === 3 && (
              // Profit Step Content
              <ProfitStepContent data={props.data} />
            )}
          </motion.div>
        </div>
      ) : (
        // If not selling yet, show the start process button
        <div className="relative w-full">
          {/* Gradient Button */}
          <Button
            onPress={onOpen}
            isDisabled={
              walletData.balance < Number(props.data.buyTargetMax) + 10
            }
            className="w-full h-32 bg-gradient-to-r from-blue-500 to-purple-600 rounded-xl p-5 text-white font-bold text-lg hover:from-blue-600 hover:to-purple-700 transition-colors duration-300"
          >
            Acheter & Revendre
          </Button>

          {/* Price Chip Positioned at Top Right */}
          <div className="absolute top-2 right-2">
            <Chip
              color={
                walletData.balance < Number(props.data.buyTargetMax) + 10
                  ? "danger"
                  : "success"
              }
              className="px-3 py-1 text-sm font-semibold"
            >
              {Number(props.data.buyTargetMax) + 10}€
            </Chip>
          </div>
        </div>
      )}

      {/* Start Process Modal */}
      {/* Start Process Modal */}
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="bg-gray-800 text-white"
        aria-labelledby="start-process-modal-title"
        classNames={{ base: "mt-0", header: "p-0" }}
      >
        <ModalContent className="overflow-y-auto max-h-screen">
          {(onClose) => (
            <>
              <ModalHeader>
                {/* Images Section */}
                <motion.div
                  className="relative overflow-hidden"
                  initial={false}
                  animate={isExpanded ? "expanded" : "collapsed"}
                  variants={imageVariants}
                  transition={{ duration: 0.3 }}
                >
                  {/* Images */}
                  {props.data.images && (
                    <div
                      className="w-full cursor-pointer"
                      onClick={() => setIsExpanded(true)}
                    >
                      <Images
                        imageList={props.data.images}
                        showThumbnails
                        thumbnailsPosition="right"
                      />
                    </div>
                  )}
                  {/* Close Button */}
                  <button
                    onClick={onClose}
                    className="absolute top-2 right-2 focus:outline-none bg-black bg-opacity-50 rounded-full p-1"
                    aria-label="Fermer"
                  >
                    <CloseIcon size={28} color="#FFFFFF" />
                  </button>
                  {/* Expand/Collapse Button */}
                  <button
                    onClick={toggleExpand}
                    className="absolute top-2 left-2 focus:outline-none bg-black bg-opacity-50 rounded-full p-1"
                    aria-label={
                      isExpanded ? "Réduire les images" : "Agrandir les images"
                    }
                  >
                    <ArrowIcon
                      size={28}
                      color="#FFFFFF"
                      direction={isExpanded ? "up" : "down"}
                    />
                  </button>
                </motion.div>
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col items-center px-4 space-y-4">
                  {/* Déduction */}
                  <div className="w-full flex justify-between items-center py-1 pt-2 rounded-md text-gray-500">
                    <span className="">Prix:</span>
                    <Chip className="bg-gray-500">
                      {Number(props.data.buyTargetMax) + 10}€
                    </Chip>
                  </div>
                  {/* Buy Prices */}
                  <div className="w-full flex justify-between items-center bg-gray-700 p-3 rounded-md">
                    <span className="font-bold bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                      Prix d'Achat:
                    </span>
                    <span>
                      {props.data.buyTargetMin}€ - {props.data.buyTargetMax}€
                    </span>
                  </div>

                  {/* Sell Prices */}
                  <div className="w-full flex justify-between items-center bg-gray-700 p-3 rounded-md">
                    <span className="font-bold bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                      Prix de Vente:
                    </span>
                    <span>
                      {props.data.sellTargetMin}€ - {props.data.sellTargetMax}€
                    </span>
                  </div>
                  <Divider className="bg-gray-500" />

                  {/* Profit */}
                  <div className="w-full flex justify-between items-center bg-gray-700 p-3 rounded-md">
                    <span className="font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">
                      Profit Estimé:
                    </span>
                    <span>
                      {props.data.sellTargetMin - props.data.buyTargetMax}€ -{" "}
                      {props.data.sellTargetMax - props.data.buyTargetMin}€
                    </span>
                  </div>

                  {/* Solde Après Vente */}
                  <div className="w-full flex justify-between items-center bg-green-600 p-3 rounded-md">
                    <span className="font-bold text-white text-xl">
                      Solde Après Vente:
                    </span>
                    <span className="text-xl font-semibold">
                      {`${
                        walletData.balance -
                        (Number(props.data.buyTargetMax) + 10) +
                        props.data.sellTargetMin
                      }€`}{" "}
                      -{" "}
                      {`${
                        walletData.balance -
                        (Number(props.data.buyTargetMin) + 10) +
                        props.data.sellTargetMax
                      }€`}
                    </span>
                  </div>

                  {/* Error Message */}
                  {error && (
                    <div className="mt-4 text-red-500 font-semibold">
                      {error}
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter className="flex justify-end space-x-2">
                <Button
                  variant="light"
                  color="danger"
                  onPress={() => onOpenChange(false)}
                  className="px-4 py-2 rounded-md"
                >
                  Annuler
                </Button>
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <Button
                    color="primary"
                    onPress={handleStartProcess}
                    className="px-4 py-2 rounded-md"
                  >
                    Confirmer
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

/** Step Content Components **/

const PurchaseStepContent = ({ data }) => {
  const handleAcceptOffer = async () => {
    const itemsRef = doc(db, "items", data.id);
    await updateDoc(itemsRef, { sellConfirmed: true });
  };
  const handleRefuseOffer = async () => {
    const itemsRef = doc(db, "items", data.id);
    await updateDoc(itemsRef, { sellConfirmed: false });
  };
  // Content specific to the Purchase step
  return (
    <>
      {data.status == "buyConfirm" && (
        <div className="border-4 border-yellow-300 p-4 flex flex-col md:flex-row justify-between items-start gap-4 md:items-center rounded-xl">
          <div className="flex flex-col gap-2 items-start">
            <Chip className="font-bold" size="lg" color="primary">
              Meilleure offre:{" "}
              <span className="font-semibold">{data.buyConfirmPrice}€</span>
            </Chip>
            <Chip className="font-bold" size="lg" color="success">
              Benefices:{" "}
              <span className="font-semibold">
                {data.sellTargetMin - data.buyConfirmPrice - 10}-
                {data.sellTargetMax - data.buyConfirmPrice - 10}€
              </span>
            </Chip>
          </div>
          <div className="w-full flex gap-2 justify-end">
            <Button variant="light" color="danger" onPress={handleRefuseOffer}>
              Refuser
            </Button>
            <Button color="primary" onPress={handleAcceptOffer}>
              Acheter
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const HandlingStepContent = ({ data }) => {
  // Content specific to the Handling step
  return <></>;
};

const ResaleStepContent = ({ data }) => {
  const handleAcceptOffer = async () => {
    const itemsRef = doc(db, "items", data.id);
    await updateDoc(itemsRef, { sellConfirmed: true });
  };
  const handleRefuseOffer = async () => {
    const itemsRef = doc(db, "items", data.id);
    await updateDoc(itemsRef, { sellConfirmed: false });
  };
  // Content specific to the Resale step
  return (
    <>
      {data.status == "sellConfirm" &&
        (data.sellConfirmed == null ? (
          <div className="border-4 border-yellow-300 p-4 flex flex-col md:flex-row justify-between items-start gap-4 md:items-center rounded-xl">
            <div className="flex flex-col gap-2 items-start">
              <Chip className="font-bold" size="lg" color="primary">
                Meilleure offre:{" "}
                <span className="font-semibold">{data.sellConfirmPrice}€</span>
              </Chip>
              <Chip
                className="font-bold"
                size="lg"
                color={
                  data.sellConfirmPrice - data.buyPrice - 10 > 0
                    ? "success"
                    : "danger"
                }
              >
                Benefices:{" "}
                <span className="font-semibold">
                  {data.sellConfirmPrice - data.buyPrice - 10}€
                </span>
              </Chip>
            </div>
            <div className="w-full flex gap-2 justify-end">
              <Button
                variant="light"
                color="danger"
                onPress={handleRefuseOffer}
              >
                Refuser
              </Button>
              <Button color="success" onPress={handleAcceptOffer}>
                Vendre
              </Button>
            </div>
          </div>
        ) : (
          <div className="p-4 flex items-center gap-4 md:items-center rounded-xl">
            {data.sellConfirmed ? (
              <>
                <Chip color="success">{data.sellConfirmPrice}€</Chip>
                <span>Offre accepte, en cours d'achat</span>
              </>
            ) : (
              <>
                <Chip color="danger">{data.sellConfirmPrice}€</Chip>
                <span>
                  Offre refuse, on essaye de negocier un meuilleur prix
                </span>
              </>
            )}
          </div>
        ))}
    </>
  );
};

const ProfitStepContent = ({ data }) => {
  // Utilisation de useMotionValue pour animer le montant du profit
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));

  useEffect(() => {
    const controls = animate(count, data.profit, { duration: 2 });
    return controls.stop;
  }, [data.profit]);

  // Dimensions de la fenêtre pour le composant Confetti
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="rounded-xl relative flex flex-col items-center justify-center h-full">
      {/* Confetti pour célébrer le profit */}
      <Confetti
        width={dimensions.width}
        height={dimensions.height}
        numberOfPieces={200}
        recycle={false}
        run={true}
      />

      {/* Arrière-plan avec dégradé vert animé */}
      <motion.div
        className="rounded-xl absolute inset-0 bg-gradient-to-br from-green-400 to-green-600"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />

      {/* Affichage du montant du profit avec animation */}
      <motion.div
        className="relative top-3 z-10 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg text-white font-bold text-6xl p-10 rounded-full shadow-lg"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 260, damping: 20 }}
      >
        <motion.span>{rounded}</motion.span>€
      </motion.div>

      {/* Message de félicitations avec animation */}
      <motion.p
        className="relative z-10 text-2xl text-white mt-6 text-center font-semibold"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 2 }}
      >
        Félicitations ! Vous avez réalisé un profit !
      </motion.p>
    </div>
  );
};
