import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useHref,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./layout/generalLayout/Layout";
import SettingsLayout from "./layout/settingsLayout/SettingsLayout";
import WelcomeLayout from "./layout/welcomeLayout/WelcomeLayout";
import AboutUsPage from "./pages/aboutus/AboutUsPage";
import DeclarePage from "./pages/declare/DeclarePage";
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/login/LoginPage";
import ProfileStore from "./pages/profile/ProfileStore";
import ExplorePage from "./pages/selling/ExplorePage";
import AccountInfo from "./pages/settings/AccountInfo";
import AccountPrivacy from "./pages/settings/AccountPrivacy";
import CreditsPage from "./pages/settings/CreditsPage";
import PasswordNSecurity from "./pages/settings/PasswordNSecurity";
import PrivacyPolicyPage from "./pages/settings/PrivacyPolicyPage";
import StorePrivacy from "./pages/settings/StorePrivacy";
import TermsNConditions from "./pages/settings/TermsNConditions";
import WhatYouSell from "./pages/settings/WhatYouSell";
import SignupPage from "./pages/signup/SignupPage";
import WalletPage from "./pages/wallet/WalletPage";
import WarehousePage from "./pages/warehouse/WarehousePage";
import WelcomePage from "./pages/welcome/WelcomePage";
import ServicesLayout from "./layout/ServicesLayout.js/ServicesLayout";
import Negociations from "./pages/services/Negociations";
import Stockage from "./pages/services/Stockage";
import Livraison from "./pages/services/Livraison";
import GestionArgent from "./pages/services/GestionArgent";
import AuthLayout from "./layout/authLayout/AuthLayout";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { db } from "./firebase";
import { useWallet } from "./context/WalletContext";
import StatisticsPage from "./pages/statistics/StatisticsPage";
import VerifyEmailPage from "./pages/verifyEmail/VerifyEmailPage"; // Add the VerifyEmailPage component
import FonctionnalitesPage from "./pages/fonctionnalites/FonctionnalitesPage";
import ContactPage from "./pages/contact/ContactPage";
import ExclusiveOfferPage from "./pages/offer/ExclusiveOfferPage";
import TarifsPage from "./pages/tarifs/TarifsPage";

function App() {
  const [items, setItems] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const { setMinEstimatedBalance, setMaxEstimatedBalance } = useWallet();
  const navigate = useNavigate();

  // // Redirect to verification page if email is not verified
  // useEffect(() => {
  //   if (currentUser && !currentUser.emailVerified) {
  //     navigate("/verify-email");
  //   }
  // }, [currentUser, navigate]);

  useEffect(() => {
    if (currentUser) {
      const unsub = onSnapshot(
        query(collection(db, "items"), where("owner", "==", currentUser.uid)),
        (snapShot) => {
          let list = [];
          let minEstimatedBalance = 0;
          let maxEstimatedBalance = 0;
          snapShot.docs.forEach((doc) => {
            let docData = doc.data();
            list.push({ id: doc.id, ...docData });
            if (docData.status !== "saved" && docData.status !== "concluded") {
              minEstimatedBalance += docData.sellTargetMin - 10;
              maxEstimatedBalance +=
                docData.sellTargetMax +
                docData.buyTargetMax -
                docData.buyTargetMin -
                10;
            }
          });
          setMinEstimatedBalance(minEstimatedBalance);
          setMaxEstimatedBalance(maxEstimatedBalance);
          setItems([...list]);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.log(error);
        }
      );
      return () => {
        unsub();
      };
    }
  }, [currentUser]);

  return (
    <Routes>
      <Route element={<WelcomeLayout />}>
        <Route path="/" element={<Navigate replace to="/welcome" />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/features" element={<FonctionnalitesPage />} />
        <Route path="/pricing" element={<TarifsPage />} />
        <Route path="/exclusive-offer" element={<ExclusiveOfferPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<Layout isLoading={isLoading} items={items} />}>
          <Route
            path="/home"
            element={<WarehousePage isLoading={isLoading} items={items} />}
          />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/stats" element={<StatisticsPage />} />
        </Route>
        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="/settings/account-info" element={<AccountInfo />} />
          <Route
            path="/settings/account-privacy"
            element={<AccountPrivacy />}
          />
          <Route
            path="/settings/password-security"
            element={<PasswordNSecurity />}
          />
          <Route path="/settings/what-you-sell" element={<WhatYouSell />} />

          <Route path="/settings/store-privacy" element={<StorePrivacy />} />
          <Route path="/settings/about-us" element={<AboutUsPage />} />
          <Route
            path="/settings/terms-conditions"
            element={<TermsNConditions />}
          />
          <Route path="/settings/credits" element={<CreditsPage />} />
          <Route
            path="/settings/privacy-policy"
            element={<PrivacyPolicyPage />}
          />
        </Route>
      </Route>

      {/* <Route path="/verify-email" element={<VerifyEmailPage />} /> */}

      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
}

export default App;
