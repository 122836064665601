// EditItem.js

import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "@firebase/firestore";
import { Button, Input, Textarea, Divider, Chip } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import Images from "../../components/images/Images";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase";
import { VintedItemContext } from "../../context/VintedItemContext";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "../../icons/CloseIcon";
import EditIcon from "../../icons/EditIcon";
import { motion } from "framer-motion";
import ArrowIcon from "../../icons/ArrowIcon";
import ExternalLinkIcon from "../../icons/ExternalLinkIcon";

export default function EditItem(props) {
  const { currentUser } = useContext(AuthContext);
  const { vintedItem, dispatch } = useContext(VintedItemContext);
  const [data, setData] = useState(props.data);
  const [errors, setErrors] = useState({});
  const [isErrors, setIsErrors] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle images expansion
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const fetch = async () => {
    try {
      const docRef = doc(db, "items", props.itemId);
      const docSnap = await getDoc(docRef);
      setData(docSnap.data());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.itemId) {
      fetch();
    } else if (vintedItem) {
      setData({ ...vintedItem });
    }
  }, [props.itemId, vintedItem]);

  const handleClose = () => {
    if (vintedItem) {
      dispatch({ type: "REMOVE", payload: null });
    } else if (props.itemId) {
      searchParams.delete("editItem");
      setSearchParams(searchParams);
    }
  };

  const validateFields = () => {
    const newErrors = {};
    const buyTargetMinThreshold = data.price ? data.price * 0.65 : null;

    if (!data.title) newErrors.title = "Le titre ne doit pas être vide";
    if (!data.description)
      newErrors.description = "La description ne doit pas être vide";
    if (!data.size) newErrors.size = "La taille ne doit pas être vide";
    if (!data.brand) newErrors.brand = "La marque ne doit pas être vide";
    if (!data.condition) newErrors.condition = "L'état ne doit pas être vide";
    if (!data.category)
      newErrors.category = "La catégorie ne doit pas être vide";
    if (!data.color) newErrors.color = "La couleur ne doit pas être vide";

    // Price-related validations
    if (data.price) {
      if (data.buyTargetMin < buyTargetMinThreshold) {
        newErrors.buyTargetMin =
          "L'objectif d'achat min ne doit pas être plus de 35% inférieur au prix";
      }
      if (data.buyTargetMax >= data.price) {
        newErrors.buyTargetMax =
          "L'objectif d'achat max doit être inférieur au prix";
      }
      if (data.buyTargetMax <= data.buyTargetMin) {
        newErrors.buyTargetMax =
          "L'objectif d'achat max doit être supérieur à l'objectif d'achat min";
      }
      if (data.sellTargetMin <= data.buyTargetMax) {
        newErrors.sellTargetMin =
          "L'objectif de vente min doit être supérieur à l'objectif d'achat max";
      }
      if (data.sellTargetMax <= data.sellTargetMin) {
        newErrors.sellTargetMax =
          "L'objectif de vente max doit être supérieur à l'objectif de vente min";
      }
    }

    setErrors(newErrors);
    setIsErrors(Object.keys(newErrors).length > 0);
    return Object.keys(newErrors).length === 0;
  };

  const handleBlur = () => {
    validateFields();
  };

  const handleSave = async () => {
    if (!validateFields()) return;

    try {
      if (vintedItem) {
        const docRef = await addDoc(collection(db, "items"), {
          ...data,
          owner: currentUser.uid,
          declaredDate: serverTimestamp(),
          status: "saved",
        });
        dispatch({ type: "REMOVE", payload: null });
        searchParams.append("item", docRef.id);
        setSearchParams(searchParams);
      } else if (props.itemId) {
        const itemsRef = doc(db, "items", props.itemId);
        await updateDoc(itemsRef, data);
        searchParams.delete("editItem");
        searchParams.append("item", props.itemId);
        setSearchParams(searchParams);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  // Image expand/collapse animation variants
  const imageVariants = {
    collapsed: { height: 200 },
    expanded: { height: "auto" },
  };

  return (
    <div className="px-2">
      {data && (
        <motion.div
          className="bg-gray-800 rounded-xl overflow-hidden shadow-medium pb-3"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Images Section */}
          <motion.div
            className="relative overflow-hidden mb-3"
            initial={false}
            animate={isExpanded ? "expanded" : "collapsed"}
            variants={imageVariants}
            transition={{ duration: 0.3 }}
          >
            {/* Images */}
            {data.images && (
              <div className="w-full" onClick={() => setIsExpanded(true)}>
                <Images
                  imageList={data.images}
                  showThumbnails
                  thumbnailsPosition="right"
                />
              </div>
            )}
            {/* Close Button */}
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 focus:outline-none bg-black bg-opacity-50 rounded-full p-1"
              aria-label="Fermer"
            >
              <CloseIcon size={28} color="#FFFFFF" />
            </button>
            {/* Expand/Collapse Button */}
            <button
              onClick={toggleExpand}
              className="absolute top-2 left-2 focus:outline-none bg-black bg-opacity-50 rounded-full p-1"
              aria-label={
                isExpanded ? "Réduire les images" : "Agrandir les images"
              }
            >
              <ArrowIcon
                size={28}
                color="#FFFFFF"
                direction={isExpanded ? "up" : "down"}
              />
            </button>
          </motion.div>

          {data.vintedUrl && (
            <div className="px-4 py-2 flex justify-between">
              <a
                href={data.vintedUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-500 flex items-center text-sm font-medium"
              >
                Voir sur Vinted
                <ExternalLinkIcon size={16} color="#3B82F6" />
              </a>
              <Chip color="primary">
                {data.buyPrice ? data.buyPrice : data.price}€
              </Chip>
            </div>
          )}

          {/* Money Inputs with Gradients */}
          <div className="p-2 px-4 grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 mb-4">
            {/* Purchase Target */}
            <div className="rounded-xl p-3 md:p-4 bg-gradient-to-br from-blue-500 to-indigo-600">
              <span className="text-white text-lg font-semibold">
                Objectif d'achat
              </span>
              <div className="mt-3 flex flex-col gap-3">
                <Input
                  label="Min (€)"
                  type="number"
                  variant="bordered"
                  size="lg"
                  className="font-bold"
                  classNames={{ input: "text-lg font-semibold" }}
                  value={data.buyTargetMin}
                  onChange={(e) =>
                    setData({
                      ...data,
                      buyTargetMin: parseInt(e.target.value),
                    })
                  }
                  onBlur={handleBlur}
                  helperText={errors.buyTargetMin}
                  helperColor="error"
                />
                <Input
                  label="Max (€)"
                  type="number"
                  variant="bordered"
                  className="text-base font-bold"
                  classNames={{ input: "text-lg font-semibold" }}
                  value={data.buyTargetMax}
                  onChange={(e) =>
                    setData({
                      ...data,
                      buyTargetMax: parseInt(e.target.value),
                    })
                  }
                  onBlur={handleBlur}
                  helperText={errors.buyTargetMax}
                  helperColor="error"
                />
              </div>
            </div>

            {/* Sell Target */}
            <div className="rounded-xl p-3 md:p-4 bg-gradient-to-br from-green-500 to-emerald-600 text-black">
              <span className="text-black text-lg font-semibold">
                Objectif de vente
              </span>
              <div className="mt-3 flex flex-col gap-3">
                <Input
                  label="Min (€)"
                  classNames={{
                    label: "!text-gray-800",
                    input: "text-lg font-semibold",
                  }}
                  type="number"
                  variant="bordered"
                  className="text-base font-bold"
                  value={data.sellTargetMin}
                  onChange={(e) =>
                    setData({
                      ...data,
                      sellTargetMin: parseInt(e.target.value),
                    })
                  }
                  onBlur={handleBlur}
                  helperText={errors.sellTargetMin}
                  helperColor="error"
                />
                <Input
                  label="Max (€)"
                  type="number"
                  classNames={{
                    label: "!text-gray-800",
                    input: "text-lg font-semibold",
                  }}
                  variant="bordered"
                  className="text-base font-bold"
                  value={data.sellTargetMax}
                  onChange={(e) =>
                    setData({
                      ...data,
                      sellTargetMax: parseInt(e.target.value),
                    })
                  }
                  onBlur={handleBlur}
                  helperText={errors.sellTargetMax}
                  helperColor="error"
                />
              </div>
            </div>

            {/* Profit */}
            <div className="bg-gray-700 rounded-xl p-3 md:p-4 flex flex-col justify-center">
              {Object.keys(errors).some((key) =>
                [
                  "buyTargetMin",
                  "buyTargetMax",
                  "sellTargetMin",
                  "sellTargetMax",
                ].includes(key)
              ) ? (
                <div className="flex flex-col gap-1">
                  {errors.buyTargetMin && (
                    <span className="text-red-500 text-sm">
                      {errors.buyTargetMin}
                    </span>
                  )}
                  {errors.buyTargetMax && (
                    <span className="text-red-500 text-sm">
                      {errors.buyTargetMax}
                    </span>
                  )}
                  {errors.sellTargetMin && (
                    <span className="text-red-500 text-sm">
                      {errors.sellTargetMin}
                    </span>
                  )}
                  {errors.sellTargetMax && (
                    <span className="text-red-500 text-sm">
                      {errors.sellTargetMax}
                    </span>
                  )}
                </div>
              ) : (
                <>
                  <span className="text-gray-400">Bénéfices estimés</span>
                  <span className="text-green-500 text-2xl md:text-3xl font-bold">
                    {data.sellTargetMin - data.buyTargetMax - 10}-
                    {data.sellTargetMax - data.buyTargetMin - 10}€
                  </span>
                </>
              )}
            </div>
          </div>

          <Divider />

          {/* Content */}
          <div className="p-2 px-4 flex flex-col md:flex-row md:gap-4">
            {/* Item Information */}
            <div className="w-full flex flex-col gap-4 mt-4 md:mt-0">
              {/* Item Details */}
              <div className="grid grid-cols-1 gap-4">
                <Input
                  label="Titre"
                  variant="bordered"
                  value={data.title}
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                  onBlur={handleBlur}
                  helperText={errors.title}
                  helperColor="error"
                  size="md"
                />
                <Input
                  label="Marque"
                  variant="bordered"
                  value={data.brand}
                  onChange={(e) => setData({ ...data, brand: e.target.value })}
                  onBlur={handleBlur}
                  helperText={errors.brand}
                  helperColor="error"
                  size="md"
                />
                <Input
                  label="Taille"
                  variant="bordered"
                  value={data.size}
                  onChange={(e) => setData({ ...data, size: e.target.value })}
                  onBlur={handleBlur}
                  helperText={errors.size}
                  helperColor="error"
                  size="md"
                />
                <Input
                  label="État"
                  variant="bordered"
                  value={data.condition}
                  onChange={(e) =>
                    setData({ ...data, condition: e.target.value })
                  }
                  onBlur={handleBlur}
                  helperText={errors.condition}
                  helperColor="error"
                  size="md"
                />
                <Input
                  label="Catégorie"
                  variant="bordered"
                  value={data.category}
                  onChange={(e) =>
                    setData({ ...data, category: e.target.value })
                  }
                  onBlur={handleBlur}
                  helperText={errors.category}
                  helperColor="error"
                  size="md"
                />
                <Input
                  label="Couleur"
                  variant="bordered"
                  value={data.color}
                  onChange={(e) => setData({ ...data, color: e.target.value })}
                  onBlur={handleBlur}
                  helperText={errors.color}
                  helperColor="error"
                  size="md"
                />
              </div>

              {/* Description */}
              <Textarea
                label="Description"
                variant="bordered"
                value={data.description}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                onBlur={handleBlur}
                helperText={errors.description}
                helperColor="error"
                size="md"
              />

              {/* Save Button */}
              <div className="flex justify-end mt-2">
                <Button
                  isDisabled={isErrors}
                  onPress={handleSave}
                  color="primary"
                  className="h-12"
                >
                  Enregistrer
                </Button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}
