// CustomVerificationInput.jsx

import React, { useRef, useState, useEffect } from "react";

const CustomVerificationInput = ({ length = 6, onComplete }) => {
  const [values, setValues] = useState(Array(length).fill(""));
  const inputsRef = useRef([]);

  // Focus the first input on mount
  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  // Handle change in input fields
  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "");
    if (value) {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      if (index < length - 1) {
        inputsRef.current[index + 1].focus();
      }
      // Check if all inputs are filled
      if (newValues.every((val) => val !== "")) {
        onComplete(newValues.join(""));
      }
    }
  };

  // Handle key down events for navigation
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (values[index] === "") {
        if (index > 0) {
          inputsRef.current[index - 1].focus();
          const newValues = [...values];
          newValues[index - 1] = "";
          setValues(newValues);
        }
      } else {
        const newValues = [...values];
        newValues[index] = "";
        setValues(newValues);
      }
    } else if (e.key === "ArrowLeft") {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    } else if (e.key === "ArrowRight") {
      if (index < length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  // Handle paste event
  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").replace(/[^0-9]/g, "");
    if (pasteData.length === length) {
      const newValues = pasteData.split("");
      setValues(newValues);
      onComplete(pasteData);
      inputsRef.current[length - 1].focus();
    }
  };

  return (
    <div className="flex space-x-2 justify-center" onPaste={handlePaste}>
      {values.map((value, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          maxLength="1"
          value={value}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputsRef.current[index] = el)}
          className="w-12 h-12 text-center text-xl bg-gray-800 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label={`Digit ${index + 1}`}
        />
      ))}
    </div>
  );
};

export default CustomVerificationInput;
