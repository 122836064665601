const ClockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 7v5h3m6 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0"
    ></path>
  </svg>
);

export default ClockIcon;
