// NavBar.jsx

import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "@firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { app } from "../../pages/firebase.config";
import { useWallet } from "../../context/WalletContext";
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Spinner,
} from "@nextui-org/react";
import Logo from "../../icons/Logo";
import UserIcon from "../../icons/UserIcon";
import HomeIcon from "../../icons/HomeIcon";
import WalletIcon from "../../icons/WalletIcon";
import GraphIcon from "../../icons/GraphIcon";
import { motion } from "framer-motion";

export default function NavBar() {
  const { dispatch, currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState({});
  const { walletData, loading, maxEstimatedBalance } = useWallet();
  const location = useLocation();
  const [navigationViaNavBar, setNavigationViaNavBar] = useState(false);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        if (data) setUserData(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données utilisateur:",
          error
        );
      }
    };
    fetchUserData();
  }, [currentUser]);

  // Logout handler
  const handleLogout = () => {
    const auth = getAuth(app);
    auth.signOut();
    dispatch({ type: "LOGOUT" });
  };

  // Navigation items
  const navItems = [
    {
      name: "Accueil",
      to: "/home",
      icon: HomeIcon,
    },
    {
      name: "Portefeuille",
      to: "/wallet",
      icon: WalletIcon,
    },
  ];

  // Animation variants
  const navItemVariants = {
    hover: {
      scale: 1.1,
      transition: { type: "spring", stiffness: 300 },
    },
    tap: { scale: 0.95 },
  };

  // NavItem component to handle active state
  function NavItem({ item }) {
    const currentSearch = location.search;
    const toWithSearch = item.to + currentSearch;

    const handleClick = () => {
      setNavigationViaNavBar(true);
    };

    return (
      <NavLink to={toWithSearch} onClick={handleClick}>
        {({ isActive }) => (
          <motion.div
            className={`flex flex-col items-center text-sm px-2 py-1 ${
              isActive ? "text-blue-500" : "text-gray-400 hover:text-blue-400"
            }`}
            whileHover="hover"
            whileTap="tap"
            variants={navItemVariants}
          >
            <item.icon
              size={24}
              color={isActive ? "rgb(59 130 246)" : "rgb(156 163 175)"}
            />
            <span className="mt-2">{item.name}</span>
            {isActive && (
              <motion.div
                layoutId="underline"
                className="w-2 h-2 rounded-full bg-blue-500 mt-2"
              ></motion.div>
            )}
          </motion.div>
        )}
      </NavLink>
    );
  }

  // Scroll to bottom on navigation via NavBar
  useEffect(() => {
    if (navigationViaNavBar) {
      window.scrollTo(0, document.body.scrollHeight);
      setNavigationViaNavBar(false);
    }
  }, [location.pathname]);

  return (
    <>
      {/* Desktop Navbar */}
      <nav className="hidden md:flex flex-col bg-gray-900 text-white w-24 h-full border-r border-gray-800">
        {/* Logo */}
        <div className="flex items-center justify-center h-20 border-b border-gray-800">
          <Logo color="white" size={60} />
        </div>
        {/* Navigation */}
        <div className="flex-1 flex flex-col justify-between py-6">
          <ul className="space-y-6">
            {navItems.map((item) => (
              <li key={item.name} className="flex justify-center">
                <NavItem item={item} />
              </li>
            ))}
          </ul>
          {/* User Section */}
          <div className="flex flex-col items-center">
            {/* Wallet Info */}
            <div className="text-center mb-4">
              {loading ? (
                <Spinner />
              ) : (
                <div>
                  <p className="text-sm font-semibold">
                    Solde: {walletData ? walletData.balance : <Spinner />}€
                  </p>
                  <p className="text-xs text-gray-500">
                    Estimation:{" "}
                    {walletData ? (
                      walletData.balance + maxEstimatedBalance
                    ) : (
                      <Spinner />
                    )}
                    €
                  </p>
                </div>
              )}
            </div>
            {/* User Avatar */}
            <Dropdown
              placement="top-center"
              className="bg-gray-800 text-white border border-gray-700 shadow-lg"
            >
              <DropdownTrigger>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="cursor-pointer"
                >
                  {userData.img ? (
                    <Avatar
                      src={userData.img}
                      alt="Avatar utilisateur"
                      size="lg"
                    />
                  ) : (
                    <div className="p-2 bg-gray-800 rounded-full">
                      <UserIcon size={28} color="white" />
                    </div>
                  )}
                </motion.div>
              </DropdownTrigger>
              <DropdownMenu aria-label="Menu utilisateur">
                <DropdownItem key="profile" className="text-left">
                  <span className="font-semibold">Connecté en tant que</span>
                  <br />
                  <span className="text-sm">{currentUser.email}</span>
                </DropdownItem>
                <DropdownItem
                  key="logout"
                  color="danger"
                  className="hover:bg-red-500 hover:text-white"
                  onClick={handleLogout}
                >
                  Se Déconnecter
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="z-50 fixed bottom-0 left-0 right-0 md:hidden bg-gray-900 text-white border-t border-gray-800 pb-6">
        <div className="flex justify-around py-2">
          {navItems.map((item) => {
            const toWithSearch = item.to + location.search;

            const handleClick = () => {
              setNavigationViaNavBar(true);
            };

            return (
              <NavLink to={toWithSearch} key={item.name} onClick={handleClick}>
                {({ isActive }) => (
                  <motion.div
                    className={`flex flex-col items-center text-xs ${
                      isActive
                        ? "text-blue-500"
                        : "text-gray-400 hover:text-blue-400"
                    }`}
                    whileHover="hover"
                    whileTap="tap"
                    variants={navItemVariants}
                  >
                    <item.icon
                      size={24}
                      color={isActive ? "rgb(59 130 246)" : "rgb(156 163 175)"}
                    />
                    <span className="mt-1">{item.name}</span>
                  </motion.div>
                )}
              </NavLink>
            );
          })}
          {/* User Icon */}
          <Dropdown
            placement="top-center"
            className="bg-gray-800 text-white border border-gray-700 shadow-lg"
          >
            <DropdownTrigger>
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="flex flex-col items-center text-xs cursor-pointer"
              >
                {userData.img ? (
                  <Avatar
                    src={userData.img}
                    alt="Avatar utilisateur"
                    size="md"
                  />
                ) : (
                  <UserIcon size={24} color="rgb(156 163 175)" />
                )}
                <span className="mt-1 text-gray-400 hover:text-blue-400">
                  Profil
                </span>
              </motion.div>
            </DropdownTrigger>
            <DropdownMenu aria-label="Menu utilisateur">
              <DropdownItem key="profile" className="text-left">
                <span className="font-semibold">Connecté en tant que</span>
                <br />
                <span className="text-sm">{currentUser.email}</span>
              </DropdownItem>

              <DropdownItem
                key="logout"
                color="danger"
                className="hover:bg-red-500 hover:text-white"
                onClick={handleLogout}
              >
                Se Déconnecter
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </nav>
    </>
  );
}
