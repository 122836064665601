// TarifsPage.jsx

import React, { useContext } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const pricingPlans = [
  {
    title: "Standard",
    price: "10€ par article revendus",
    features: [
      "Prix de livraison inclus",
      "Négociation avec le vendeur/acheteur",
      "Achat et stockage sécurisé",
      "Photos professionnelles",
      "Création de l'annonce",
    ],
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-16 w-16 text-blue-500 mx-auto"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M12 8v13m0 0l3-3m-3 3l-3-3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <circle
          cx="12"
          cy="6"
          r="3"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];

const TarifsPage = () => {
  // Variantes pour les animations Framer Motion
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2 },
    }),
  };

  const { currentUser } = useContext(AuthContext);

  return (
    <div className="font-sans">
      {/* Section Titre avec Gradient */}
      <div className="py-24 px-6 text-center bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-5xl md:text-6xl font-bold text-white mb-4"
        >
          Nos Tarifs
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-xl md:text-2xl text-gray-100"
        >
          Des solutions adaptées pour maximiser vos profits
        </motion.p>
      </div>

      {/* Section Tarifs */}
      <div className="py-16 px-6 bg-gray-900 text-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-1 gap-12">
          {pricingPlans.map((plan, index) => (
            <motion.div
              key={index}
              custom={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInUp}
              className={`flex flex-col items-center bg-gray-800 rounded-lg p-8 transform hover:-translate-y-2 transition duration-300 ${
                plan.highlighted ? "border-4 border-yellow-500" : ""
              }`}
            >
              {plan.icon}
              <h3 className="text-3xl font-semibold my-4">{plan.title}</h3>
              <p className="text-4xl font-bold mb-6">{plan.price}</p>
              <ul className="text-gray-300 mb-6 space-y-2">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <svg
                      className="h-6 w-6 text-green-500 mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M5 13l4 4L19 7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                      />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
              {currentUser ? (
                <Link to="/home">
                  <button className="px-6 py-3 bg-white text-blue-600 font-semibold text-lg rounded-md shadow-md transition transform hover:scale-105">
                    Accéder à l'application
                  </button>
                </Link>
              ) : (
                <Link to="/sign-up">
                  <button className="px-6 py-3 bg-white text-blue-600 font-semibold text-lg rounded-md shadow-md transition transform hover:scale-105">
                    Commencer Maintenant
                  </button>
                </Link>
              )}
            </motion.div>
          ))}
        </div>
      </div>

      {/* Section FAQ */}
      <div className="py-24 px-6 bg-gray-800 text-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-12 text-center">
            Questions Fréquemment Posées
          </h2>
          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-semibold mb-2">
                Y a-t-il des frais cachés ?
              </h3>
              <p className="text-gray-300">
                Non, il n'y a aucun frais caché. Vous ne payez que les frais
                indiqués pour le plan choisi.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Section CTA avec Gradient */}
      <div className="py-24 px-6 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-center">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-white">
          Démarrez Votre Aventure de Revente Aujourd'hui
        </h2>
        <p className="text-2xl md:text-3xl mb-8 text-gray-100">
          Rejoignez-nous et profitez de nos services pour maximiser vos profits.
        </p>
        {currentUser ? (
          <Link to="/home">
            <button className="px-8 py-4 bg-white text-blue-600 font-semibold text-lg rounded-md shadow-md transition transform hover:scale-105">
              Accéder à l'application
            </button>
          </Link>
        ) : (
          <Link to="/sign-up">
            <button className="px-8 py-4 bg-white text-blue-600 font-semibold text-lg rounded-md shadow-md transition transform hover:scale-105">
              Commencer Maintenant
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default TarifsPage;
