import whiteLogo from "./white-logo.png";
import blackLogo from "./black-logo.png";

const Logo = (props) => {
  return (
    <div
      style={{ display: "inline-block", width: props.size }}
      className="flex items-center justify-center"
    >
      <img src={props.isBlack ? blackLogo : whiteLogo} alt="Logo" />
    </div>
  );
};

export default Logo;
