// src/icons/ExternalLinkIcon.js

import React from "react";

function ExternalLinkIcon({ size = 16, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      stroke={color}
      className="inline-block ml-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13.828 10.172a4 4 0 015.656 0l4 4a4 4 0 010 5.656l-4 4a4 4 0 01-5.656 0l-4-4a4 4 0 010-5.656l4-4z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5h7a2 2 0 012 2v7"
      />
    </svg>
  );
}

export default ExternalLinkIcon;
