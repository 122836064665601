// WarehousePage.jsx

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Input,
  Spinner,
  Accordion,
  AccordionItem,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { SearchIcon } from "../../icons/SearchIcon";
import { VintedItemContext } from "../../context/VintedItemContext";
import { useLocation, Link } from "react-router-dom";
import List from "../../components/list/List";
import CirclteText from "../../components/circleText/CircleText";
import useHighlight from "../../components/useImportHighlight";
import { motion } from "framer-motion";
import { AuthContext } from "../../context/AuthContext";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { db } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const WarehousePage = (props) => {
  const { dispatch } = useContext(VintedItemContext);
  const [vintedInput, setVintedInput] = useState("");
  const [vintedLoading, setVintedLoading] = useState(false);
  const [error, setError] = useState(null);

  const [items, setItems] = useState([]);
  const [itemConfirm, setItemConfirm] = useState(false);
  const [itemsSaved, setItemsSaved] = useState([]);
  const [itemsSold, setItemsSold] = useState([]);

  // Function to check screen size
  const checkScreenSize = () => {
    return window.innerWidth < 768; // Tailwind's 'md' breakpoint is 768px
  };
  // State to track screen size
  const [isMobile, setIsMobile] = useState(checkScreenSize());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkScreenSize());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.items) {
      let itemsSaved = props.items.filter((el) => el.status === "saved");
      let confirm = false;

      let items = props.items.filter((el) => {
        if (el.status === "buyConfirm" || el.status === "sellConfirm") {
          if (el.buyConfirmed == null || el.sellConfirmed == null)
            confirm = true; // Set confirm to true if the condition matches
        }
        return el.status !== "saved" && el.status !== "concluded";
      });
      setItemConfirm(confirm);

      let itemsSold = props.items.filter((el) => el.status === "concluded");
      setItems(items);
      setItemsSaved(itemsSaved);
      setItemsSold(itemsSold);
    }
  }, [props]);

  const isSellingHighlighted = useHighlight("sellingHighlight");

  // Animation variants for Framer Motion
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  // Import useLocation to access query parameters
  const location = useLocation();

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const sellingHighlightParam = searchParams.get("sellingHighlight");

  // State to manage expanded accordion items
  const [expandedKeys, setExpandedKeys] = useState(() => {
    const initialKeys = isMobile ? [] : ["1", "2"];
    if (sellingHighlightParam === "true" && !initialKeys.includes("1")) {
      initialKeys.push("1");
    }
    return initialKeys;
  });

  // Handler for accordion change
  const handleAccordionChange = (keys) => {
    setExpandedKeys(keys);
  };

  return (
    <div className="w-full">
      <Accordion
        isCompact
        variant="splitted"
        selectedKeys={expandedKeys}
        onSelectionChange={handleAccordionChange}
        className="space-y-1"
      >
        {/* Selling in Progress Section */}
        <AccordionItem
          key="1"
          aria-label="En cours de revente"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-primary text-white rounded-full text-lg font-bold">
                3
              </div>
              <h2 className="text-xl font-bold text-white">
                En cours de revente...
              </h2>
            </div>
          }
          className={`bg-gray-800 rounded-xl py-2 ${
            itemConfirm
              ? "border-4 border-yellow-300"
              : "border-0 border-transparent"
          }`}
        >
          <motion.div
            className={`p-2 ${isSellingHighlighted ? "animate-shine" : ""}`}
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            <List
              isLoading={props.isLoading}
              data={items}
              emptyMessage={
                <div className="flex flex-col items-center justify-center h-64 text-center">
                  <h2 className="text-xl font-semibold text-gray-400">
                    {itemsSaved.length === 0
                      ? "Commencez par importer"
                      : "Commencez la revente"}
                  </h2>
                  <Link
                    to={
                      itemsSaved.length === 0
                        ? "/home?importHighlight=true"
                        : "/home?startSellHighlight=true"
                    }
                    className="mt-4 text-blue-500 hover:underline"
                  >
                    {itemsSaved.length === 0
                      ? "Importer un article"
                      : "Démarrer la revente"}
                  </Link>
                </div>
              }
            />
          </motion.div>
        </AccordionItem>

        {/* Sold Items Section */}
        <AccordionItem
          key="2"
          aria-label="Revendus"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-primary text-white rounded-full text-lg font-bold">
                4
              </div>
              <h2 className="text-xl font-bold text-white">Revendus !</h2>
            </div>
          }
          className="bg-gray-800 rounded-xl py-2"
        >
          <motion.div
            className="p-2"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            <List
              isLoading={props.isLoading}
              data={itemsSold}
              emptyMessage={
                <div className="flex flex-col items-center justify-center h-64 text-center">
                  <h2 className="text-xl font-semibold text-gray-400">
                    {itemsSaved.length === 0
                      ? "Commencez par importer"
                      : items.length === 0
                      ? "Commencez la revente"
                      : "Bientôt..."}
                  </h2>
                  <Link
                    to={
                      itemsSaved.length === 0
                        ? "/home?importHighlight=true"
                        : items.length === 0
                        ? "/home?startSellHighlight=true"
                        : "/home?sellingHighlight=true"
                    }
                    className="mt-4 text-blue-500 hover:underline"
                  >
                    {itemsSaved.length === 0
                      ? "Importer un article"
                      : items.length === 0
                      ? "Démarrer la revente"
                      : "Voir les articles en cours"}
                  </Link>
                </div>
              }
            />
          </motion.div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default WarehousePage;
