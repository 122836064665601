import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import DangerIcon from "../../icons/DangerIcon";
import PurchaseIcon from "../../icons/PurchaseIcon";
import HandlingIcon from "../../icons/HandlingIcon";
import ResaleIcon from "../../icons/ResaleIcon";
import ProfitIcon from "../../icons/ProfitIcon";

const ItemCard = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function openSelected() {
    let item;
    if (searchParams.has("item")) {
      item = searchParams.getAll("item");
      searchParams.delete("item");
    }
    searchParams.append("item", props.data.id);

    setSearchParams(searchParams);

    // Faire défiler vers le haut de la page
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const [status, setStatus] = useState("");

  useEffect(() => {
    switch (props.data.status) {
      case "saved":
        return setStatus("Sauvegarde");
      case "negotiating":
        return setStatus("En cours de négociation");
      case "buyConfirm":
        return setStatus(
          <div className="flex gap-1">
            <DangerIcon color="orange" size={20} />
            Confirmer l'achat
          </div>
        );
      case "receiving":
        return setStatus("En attente de réception");
      case "handling":
        return setStatus("En cours de traitement");
      case "selling":
        return setStatus("En cours de vente");
      case "sold":
        return setStatus("Vendu (non envoyé)");
      case "sent":
        return setStatus("Vendu (envoyé)");
      case "concluded":
        return setStatus("Revendu");
      default:
        return setStatus(props.data.status);
    }
  }, [props.data]);

  // Définition des étapes avec les icônes correspondantes
  const steps = [
    {
      title: "Achat",
      icon: <PurchaseIcon size={24} color="white" />,
      status: ["negotiating", "buyConfirm", "buying"],
    },
    {
      title: "Gestion",
      icon: <HandlingIcon size={24} color="white" />,
      status: ["receiving", "handling"],
    },
    {
      title: "Revente",
      icon: <ResaleIcon size={24} color="white" />,
      status: ["selling", "sellConfirm", "sold", "sent"],
    },
    {
      title: "Bénéfices",
      icon: <ProfitIcon size={24} color="white" />,
      status: ["concluded"],
    },
  ];

  // Vérifier si le DangerIcon doit être affiché
  const shouldShowDangerIcon =
    (props.data.status === "buyConfirm" ||
      props.data.status === "sellConfirm") &&
    (props.data.sellConfirmed == null || props.data.buyConfirmed == null);

  // Obtenir l'icône correspondant à l'étape actuelle
  const currentStep = steps.find((step) =>
    step.status.includes(props.data.status)
  );

  return (
    <>
      <div
        onClick={openSelected}
        className={`hover:cursor-pointer overflow-hidden rounded-xl h-full aspect-square col-span-12 sm:col-span-5 relative ${
          shouldShowDangerIcon
            ? "border-4 border-yellow-100"
            : "border-0 border-transparent"
        }`}
      >
        {/* Affichage conditionnel de l'icône en haut à droite */}
        {shouldShowDangerIcon ? (
          <div className="z-20 rounded-full bg-yellow-100 p-1 absolute top-1 right-1">
            <DangerIcon size={28} color="#EA580B" />
          </div>
        ) : currentStep ? (
          <div className="z-20 rounded-full bg-black/25 p-2 absolute top-1 right-1 drop-shadow-xl">
            {currentStep.icon}
          </div>
        ) : null}

        <div className="absolute z-10 top-1 left-2 flex-col items-start">
          <h4
            style={{
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
            className="text-black text-white font-medium text-2xl text-left"
          >
            {props.data.title}
          </h4>
        </div>
        <Image
          removeWrapper
          alt="Image de l'article"
          className="z-0 w-full h-full object-cover"
          src={
            props.data.images
              ? props.data.images[0]
              : "https://nextui.org/images/hero-card.jpeg"
          }
        />
        <div className="w-full absolute bottom-2 right-2 z-10 flex flex-col items-end gap-2 justify-end">
          <Chip className="bg-green-900 flex w-full items-center justify-end">
            <span className="text-green-500 font-semibold">
              {props.data.profit ? (
                <>{props.data.profit}€</>
              ) : props.data.sellPrice ? (
                <>{props.data.sellPrice - props.data.buyPrice - 10}€</>
              ) : props.data.buyPrice ? (
                <>
                  {props.data.sellTargetMin - props.data.buyPrice - 10}-
                  {props.data.sellTargetMax - props.data.buyPrice - 10}€
                </>
              ) : (
                <>
                  {props.data.sellTargetMin - props.data.buyTargetMax - 10}-
                  {props.data.sellTargetMax - props.data.buyTargetMin - 10}€
                </>
              )}
            </span>
          </Chip>
        </div>
      </div>
    </>
  );
};

export default ItemCard;
