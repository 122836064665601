// StatisticsPage.jsx

import React, { useEffect, useState, useContext } from "react";
import {
  Accordion,
  AccordionItem,
  Spinner,
  Tabs,
  Tab,
  Button,
} from "@nextui-org/react";
import ApexCharts from "react-apexcharts";
import { collection, doc, getDoc } from "firebase/firestore";
import { useSearchParams, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase";
import { useWallet } from "../../context/WalletContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import CirclteText from "../../components/circleText/CircleText";
import RankingIcon from "../../icons/RankingIcon";
import ProgressIcon from "../../icons/ProgressIcon";
import useHighlight from "../../components/useImportHighlight";
import { motion } from "framer-motion";

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const StatisticsPage = () => {
  const { currentUser } = useContext(AuthContext);
  const { walletData } = useWallet();
  const [searchParams, setSearchParams] = useSearchParams();

  const [userTransactions, setUserTransactions] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("allTime");
  const [error, setError] = useState(null);

  const [renderedStats, setRenderedStats] = useState();

  const location = useLocation();
  const isStatsHighlighted = useHighlight("statsHighlight");

  // Function to check screen size
  const checkScreenSize = () => {
    return window.innerWidth < 768;
  };
  const [isMobile, setIsMobile] = useState(checkScreenSize());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkScreenSize());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // State to manage expanded accordion items
  const [expandedKeys, setExpandedKeys] = useState(() => {
    const initialKeys = isMobile ? [] : ["1", "2"];
    return initialKeys;
  });

  // Handler for accordion change
  const handleAccordionChange = (keys) => {
    setExpandedKeys(keys);
  };

  // Animation variants for Framer Motion
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const renderStats = () => {
    if (!stats)
      return (
        <p className="text-gray-400">
          Aucune donnée disponible pour les statistiques.
        </p>
      );

    const statsMap = {
      allTime: {
        transactionSum: stats.balance,
        rank: stats.balanceRank,
        percentile: stats.balancePercentile,
      },
      lastMonth: {
        transactionSum: stats.lastMonthSum,
        rank: stats.lastMonthRank,
        percentile: stats.lastMonthPercentile,
      },
      lastYear: {
        transactionSum: stats.lastYearSum,
        rank: stats.lastYearRank,
        percentile: stats.lastYearPercentile,
      },
    };

    const currentStats = statsMap[activeTab];

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
        <div className="bg-gray-800 rounded-xl p-6">
          <h3 className="text-xl font-bold text-white">Rang</h3>
          <p className="text-2xl text-white">{`${currentStats.rank}`}</p>
        </div>
        <div className="bg-gray-800 rounded-xl p-6">
          <h3 className="text-xl font-bold text-white">Top</h3>
          <p className="text-2xl text-white">{`${Math.floor(
            currentStats.percentile
          )}%`}</p>
        </div>
        <div className="bg-gray-800 rounded-xl p-6">
          <h3 className="text-xl font-bold text-white">Solde</h3>
          <p className="text-2xl text-white">{`${currentStats.transactionSum}€`}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setRenderedStats(renderStats());
  }, [stats, activeTab]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!currentUser?.uid) return;

      try {
        const walletRef = doc(db, "wallets", currentUser.uid);
        const walletDoc = await getDoc(walletRef);

        if (!walletDoc.exists()) {
          console.error("Portefeuille introuvable");
          return;
        }

        const walletData = walletDoc.data();
        const transactionIds = walletData.transactions || [];

        const transactionsCollection = collection(db, "transactions");
        const transactionsPromises = transactionIds.map((id) =>
          getDoc(doc(transactionsCollection, id))
        );

        const transactionDocs = await Promise.all(transactionsPromises);
        const transactionData = transactionDocs
          .filter((doc) => doc.exists())
          .map((doc) => ({ id: doc.id, ...doc.data() }));

        const itemPromises = transactionData.map(async (transaction) => {
          if (transaction.itemId) {
            const itemDoc = await getDoc(doc(db, "items", transaction.itemId));
            return {
              ...transaction,
              itemData: itemDoc.exists() ? itemDoc.data() : null,
            };
          }
          return transaction;
        });

        const transactionsWithItems = await Promise.all(itemPromises);

        transactionsWithItems.sort((a, b) => a.date.toDate() - b.date.toDate());

        setUserTransactions(transactionsWithItems);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des transactions:",
          error
        );
      }
    };

    const fetchUserStats = async () => {
      const functions = getFunctions();
      try {
        const getUserStats = httpsCallable(functions, "getUserStats");
        const result = await getUserStats();
        setStats(result.data);
      } catch (err) {
        setError(err.message);
        console.error("Erreur lors de la récupération des statistiques:", err);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchTransactions(), fetchUserStats()]);
      setLoading(false);
    };

    fetchData();
  }, [currentUser]);

  const generateChartData = () => {
    if (!walletData || userTransactions.length === 0) return null;

    let balance = walletData.balance;
    const balanceOverTime = [];

    userTransactions.sort((a, b) => b.date.toDate() - a.date.toDate());

    userTransactions.forEach((transaction) => {
      balance -= transaction.amount;
      balanceOverTime.push({
        date: transaction.date.toDate(),
        balance,
        amount: transaction.amount,
        itemId: transaction.itemId,
        item: transaction.itemData,
      });
    });

    const now = new Date();
    balanceOverTime.push({
      date: now,
      balance: walletData.balance,
    });

    balanceOverTime.sort((a, b) => a.date - b.date);

    return {
      series: [
        {
          name: "Solde au fil du temps",
          data: balanceOverTime.map((entry) => ({
            x: entry.date,
            y: entry.balance,
            amount: entry.amount,
            item: entry.item,
            itemId: entry.itemId,
          })),
        },
      ],
    };
  };

  const [chartState, setChartState] = useState(generateChartData());

  useEffect(() => {
    setChartState(generateChartData());
  }, [userTransactions]);

  if (error) return <p className="text-red-500">Erreur: {error}</p>;

  return (
    <div className="w-full">
      <Accordion
        isCompact
        variant="splitted"
        selectedKeys={expandedKeys}
        onSelectionChange={handleAccordionChange}
        className="space-y-1"
      >
        {/* Ranking Section */}
        <AccordionItem
          key="1"
          aria-label="Classement"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-indigo-600 text-white rounded-full text-lg font-bold">
                <RankingIcon size={20} color="white" />
              </div>
              <h2 className="text-xl font-bold text-white">Classement</h2>
            </div>
          }
          className="bg-gray-800 rounded-xl py-2"
        >
          <motion.div
            className={`p-2 ${isStatsHighlighted ? "animate-shine" : ""}`}
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            {loading ? (
              <div className="h-full flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <Tabs
                  isVertical={isMobile}
                  variant="bordered"
                  selectedKey={activeTab}
                  onSelectionChange={setActiveTab}
                  className="mb-5"
                >
                  <Tab key="allTime" title="Tous les temps"></Tab>
                  <Tab key="lastMonth" title="Le mois dernier"></Tab>
                  <Tab key="lastYear" title="L'année dernière"></Tab>
                </Tabs>
                {renderedStats}
              </>
            )}
          </motion.div>
        </AccordionItem>

        {/* Balance Progression Section */}
        <AccordionItem
          key="2"
          aria-label="Progression de Solde"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-indigo-600 text-white rounded-full text-lg font-bold">
                <ProgressIcon size={20} color="white" />
              </div>
              <h2 className="text-xl font-bold text-white">
                Progression de Solde
              </h2>
            </div>
          }
          className="bg-gray-800 rounded-xl py-2"
        >
          <motion.div
            className="p-2"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            {loading ? (
              <div className="h-full flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                {chartState ? (
                  <ApexCharts
                    options={{
                      chart: {
                        type: "line",
                        toolbar: { show: false },
                        background: "transparent",
                        foreColor: "#fff",
                      },
                      xaxis: {
                        type: "datetime",
                        labels: {
                          style: { colors: "#fff" },
                          formatter: (value) => {
                            const date = new Date(value);
                            return `${date.getDate()} ${
                              months[date.getMonth()]
                            }`;
                          },
                        },
                        axisBorder: { show: true, color: "#555" },
                        axisTicks: { show: true, color: "#555" },
                      },
                      yaxis: {
                        labels: {
                          style: { colors: "#fff" },
                          formatter: (value) => `${value}€`,
                        },
                      },
                      grid: {
                        borderColor: "#555",
                        row: {
                          colors: ["#3B3B41", "transparent"],
                          opacity: 0.1,
                        },
                      },
                      stroke: {
                        curve: "smooth",
                        width: 2,
                      },
                      tooltip: {
                        theme: "dark",
                        x: { format: "dd MMM yyyy" },
                        y: {
                          formatter: (value) => `${value}€`,
                        },
                      },
                    }}
                    series={chartState.series}
                    type="line"
                    height="350"
                  />
                ) : (
                  <p className="text-gray-400">
                    Aucune donnée disponible pour votre solde.
                  </p>
                )}
              </>
            )}
          </motion.div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default StatisticsPage;
