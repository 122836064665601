// Images.jsx

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Images = ({ imageList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="w-full flex flex-col md:grid md:grid-cols-2 md:grid-cols-[70%_30%] md:aspect-square md:grid-rows-[70%] md:mb-[-30%]">
      {/* Big Image */}
      <div className="w-full">
        <AnimatePresence mode="wait">
          <motion.img
            key={currentIndex}
            src={imageList[currentIndex]}
            alt={`Image ${currentIndex + 1}`}
            className="w-full aspect-square h-auto object-cover rounded-none"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          />
        </AnimatePresence>
      </div>

      {/* Thumbnails */}
      <div className="mt-0 overflow-y-auto overflow-x-auto">
        <div className="flex flex-row md:flex-col ">
          {imageList.map((image, index) => (
            <motion.img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`w-24 h-24 md:w-full md:h-full aspect-square object-cover cursor-pointer ${
                currentIndex === index ? "" : "opacity-30"
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Images;
