import React, { createContext, useContext, useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { AuthContext } from "./AuthContext"; // Ensure AuthContext is properly imported
import { db } from "../firebase";

// Create the WalletContext
export const WalletContext = createContext();

// WalletProvider component
export const WalletProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [walletData, setWalletData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [minEstimatedBalance, setMinEstimatedBalance] = useState(null);
  const [maxEstimatedBalance, setMaxEstimatedBalance] = useState(null);

  useEffect(() => {
    // Check if user is signed in
    if (currentUser) {
      const walletRef = doc(db, "wallets", currentUser.uid);

      // Listen to real-time updates on the wallet document
      const unsubscribe = onSnapshot(
        walletRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            setWalletData(docSnapshot.data());
          } else {
            console.error("No wallet data found for this user.");
            setWalletData(null);
          }
          console.log(docSnapshot.data());

          setLoading(false);
        },
        (error) => {
          console.error("Error fetching wallet data:", error);
          setLoading(false);
        }
      );

      // Clean up listener on component unmount
      return () => unsubscribe();
    } else {
      // If not signed in, reset wallet data and loading state
      setWalletData(null);
      setLoading(false);
    }
  }, [currentUser]);

  return (
    <WalletContext.Provider
      value={{
        walletData,
        loading,
        minEstimatedBalance,
        maxEstimatedBalance,
        setMinEstimatedBalance,
        setMaxEstimatedBalance,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

// Hook to use WalletContext data easily in other components
export const useWallet = () => useContext(WalletContext);
