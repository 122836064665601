import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function useHighlight(paramName) {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramValue = searchParams.get(paramName);

  useEffect(() => {
    if (paramValue === "true") {
      const timer = setTimeout(() => {
        searchParams.delete(paramName);
        setSearchParams(searchParams);
      }, 4500);

      return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }
  }, [paramValue, paramName, searchParams, setSearchParams]);

  return paramValue === "true";
}

export default useHighlight;
