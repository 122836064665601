// WalletPage.jsx

import React, { useState, useEffect, useContext } from "react";
import { Accordion, AccordionItem, Button, Spinner } from "@nextui-org/react";
import { motion } from "framer-motion";
import { doc, getDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { useWallet } from "../../context/WalletContext";
import Table from "../../components/table/Table";
import CirclteText from "../../components/circleText/CircleText";
import WalletIcon from "../../icons/WalletIcon";
import MoneyIcon from "../../icons/MoneyIcon";
import ClockIcon from "../../icons/ClockIcon";
import useHighlight from "../../components/useImportHighlight";
import { useLocation } from "react-router-dom";

const WalletPage = () => {
  const { currentUser } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const { walletData, loading, minEstimatedBalance, maxEstimatedBalance } =
    useWallet();

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!currentUser?.uid) return;

      try {
        const walletRef = doc(db, "wallets", currentUser.uid);
        const walletDoc = await getDoc(walletRef);

        if (!walletDoc.exists()) {
          console.error("Wallet not found");
          return;
        }

        const walletData = walletDoc.data();
        const transactionIds = walletData.transactions || [];

        const transactionsCollection = collection(db, "transactions");
        const transactionsPromises = transactionIds.map((id) =>
          getDoc(doc(transactionsCollection, id))
        );

        const transactionDocs = await Promise.all(transactionsPromises);
        const transactionData = transactionDocs
          .filter((doc) => doc.exists())
          .map((doc) => ({ id: doc.id, ...doc.data() }));

        setTransactions(transactionData);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setTransactionLoading(false);
      }
    };

    fetchTransactions();
  }, [currentUser]);

  // Function to check screen size
  const checkScreenSize = () => {
    return window.innerWidth < 768;
  };
  const [isMobile, setIsMobile] = useState(checkScreenSize());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkScreenSize());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Import useLocation to access query parameters
  const location = useLocation();

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const walletHighlightParam = searchParams.get("walletHighlight");

  // State to manage expanded accordion items
  const [expandedKeys, setExpandedKeys] = useState(() => {
    const initialKeys = isMobile ? [] : ["1", "2", "3"];
    if (walletHighlightParam === "true" && !initialKeys.includes("1")) {
      initialKeys.push("1");
    }
    return initialKeys;
  });

  // Handler for accordion change
  const handleAccordionChange = (keys) => {
    setExpandedKeys(keys);
  };

  // Animation variants for Framer Motion
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  // Highlighting
  const isWalletHighlighted = useHighlight("walletHighlight");

  return (
    <div className="w-full">
      <Accordion
        isCompact
        variant="splitted"
        selectedKeys={expandedKeys}
        onSelectionChange={handleAccordionChange}
        className="space-y-1"
      >
        {/* Balance Section */}
        <AccordionItem
          key="1"
          aria-label="Solde"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-yellow-500 text-white rounded-full text-lg font-bold">
                <WalletIcon size={20} color="white" />
              </div>
              <h2 className="text-xl font-bold text-white">Solde</h2>
            </div>
          }
          className="bg-gray-800 rounded-xl py-2"
        >
          <motion.div
            className={`p-2 ${isWalletHighlighted ? "animate-shine" : ""}`}
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            {walletData ? (
              <div className="flex flex-col gap-4">
                <div>
                  <span className="font-semibold text-3xl text-white">
                    {walletData.balance}€
                  </span>
                </div>
                <div>
                  <span className="text-default-400">
                    Estimation après reventes
                  </span>
                  <br />
                  <span className="font-semibold text-green-400 text-2xl">
                    {walletData.balance + minEstimatedBalance}€ -{" "}
                    {walletData.balance + maxEstimatedBalance}€
                  </span>
                </div>
              </div>
            ) : (
              <Spinner className="mt-2" />
            )}
          </motion.div>
        </AccordionItem>

        {/* Cashout Section */}
        <AccordionItem
          key="2"
          aria-label="Cashout"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-yellow-500 text-white rounded-full text-lg font-bold">
                <MoneyIcon size={20} color="white" />
              </div>
              <h2 className="text-xl font-bold text-white">Cashout</h2>
            </div>
          }
          className="bg-gray-800 rounded-xl py-2"
        >
          <motion.div
            className="p-2"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="flex flex-col items-center justify-center h-32 text-center">
              <h2 className="text-xl font-semibold text-gray-400">
                Fonctionnalité à venir
              </h2>
              <Button disabled className="mt-4" size="lg">
                Retirer mon solde
              </Button>
            </div>
          </motion.div>
        </AccordionItem>

        {/* Transactions Section */}
        <AccordionItem
          key="3"
          aria-label="Transactions"
          title={
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 bg-yellow-500 text-white rounded-full text-lg font-bold">
                <ClockIcon size={20} color="white" />
              </div>
              <h2 className="text-xl font-bold text-white">Transactions</h2>
            </div>
          }
          className="bg-gray-800 rounded-xl py-2"
        >
          <motion.div
            className="p-2"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            {!transactionLoading ? <Table data={transactions} /> : <Spinner />}
          </motion.div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default WalletPage;
