// FonctionnalitesPage.jsx

import React, { useContext } from "react";
import { motion } from "framer-motion";
import { Card, CardBody } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const features = [
  {
    title: "Automatisation Complète",
    description:
      "Laissez-nous gérer l'ensemble du processus de revente, de la négociation à l'expédition.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12 text-blue-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M12 8v4l3 3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <circle
          cx="12"
          cy="12"
          r="9"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "Gestion Simplifiée",
    description:
      "Suivez vos articles et vos profits en temps réel via notre application intuitive.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12 text-purple-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <rect
          x="3"
          y="3"
          width="18"
          height="18"
          rx="2"
          ry="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M9 9h6v6H9V9z"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "Photos Professionnelles",
    description:
      "Des images de haute qualité pour maximiser l'attrait de vos annonces.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12 text-pink-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M3 7h4l3-3h4l3 3h4v13H3V7z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <circle
          cx="12"
          cy="13"
          r="4"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    title: "Support Dédié",
    description:
      "Une équipe à votre écoute pour vous accompagner dans votre parcours de revente.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12 text-yellow-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M12 11c0-1.657 1.343-3 3-3s3 1.343 3 3-1.343 3-3 3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M12 11c0-1.657-1.343-3-3-3S6 9.343 6 11s1.343 3 3 3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M5 19h14"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </svg>
    ),
  },
];

const FonctionnalitesPage = () => {
  // Variantes pour les animations Framer Motion
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2 },
    }),
  };

  const { currentUser } = useContext(AuthContext);

  return (
    <div className="font-sans">
      {/* Section Titre avec Gradient */}
      <div className="py-24 px-6 text-center bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-5xl md:text-6xl font-bold text-white mb-12"
        >
          Nos Fonctionnalités
        </motion.h1>
      </div>

      {/* Section Fonctionnalités avec Fond Dégradé */}
      <div className="py-16 px-6 bg-gray-900 text-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              custom={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInUp}
              className="flex justify-center"
            >
              <Card className="bg-gradient-to-b from-gray-800 to-gray-700 w-full transform hover:-translate-y-2 transition duration-300">
                <CardBody className="text-center">
                  <div className="flex justify-center mb-4">{feature.icon}</div>
                  <h3 className="text-2xl font-semibold mb-4">
                    {feature.title}
                  </h3>
                  <p className="text-gray-300">{feature.description}</p>
                </CardBody>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Section CTA avec Gradient */}
      <div className="py-24 px-6 bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-center">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-white">
          Prêt à Simplifier vos Reventes ?
        </h2>
        <p className="text-2xl md:text-3xl mb-8 text-gray-100">
          Rejoignez-nous dès aujourd'hui et profitez de toutes nos
          fonctionnalités.
        </p>
        {currentUser ? (
          <Link to="/home">
            <button className="px-8 py-4 bg-white text-blue-600 font-semibold text-lg rounded-md shadow-md transition transform hover:scale-105">
              Accéder à l'application
            </button>
          </Link>
        ) : (
          <Link to="/sign-up">
            <button className="px-8 py-4 bg-white text-blue-600 font-semibold text-lg rounded-md shadow-md transition transform hover:scale-105">
              Commencer Maintenant
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default FonctionnalitesPage;
