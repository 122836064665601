import { NextUIProvider } from "@nextui-org/react";
import {
  Navigate,
  Route,
  Routes,
  useHref,
  useNavigate,
} from "react-router-dom";

export default function Provider({ children }) {
  const navigate = useNavigate();

  return (
    <NextUIProvider navigate={navigate} useHref={useHref}>
      <main className="dark text-foreground bg-background">{children}</main>
    </NextUIProvider>
  );
}
