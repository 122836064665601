import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
  Chip,
  Image,
  Pagination,
} from "@nextui-org/react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

const columns = [
  { name: "ITEM", uid: "itemId" },
  { name: "DATE", uid: "date" },
  { name: "AMOUNT", uid: "amount" },
];

export default function TransactionsTable({ data }) {
  const [itemsData, setItemsData] = useState({});
  const [loadingItems, setLoadingItems] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Pagination states
  const [page, setPage] = useState(1);
  const rowsPerPage = 5; // Adjust this number as needed

  // Sort data by date descending
  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => {
      const aDate = a.date?.toDate();
      const bDate = b.date?.toDate();
      return bDate - aDate; // Most recent first
    });
  }, [data]);

  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  // Paginated data
  const paginatedData = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return sortedData.slice(start, end);
  }, [page, sortedData]);

  useEffect(() => {
    const fetchItemsData = async () => {
      const itemsMap = {};

      for (const transaction of paginatedData) {
        const itemId = transaction.itemId;
        if (itemId && !itemsMap[itemId]) {
          try {
            const itemRef = doc(db, "items", itemId);
            const itemDoc = await getDoc(itemRef);

            if (itemDoc.exists()) {
              itemsMap[itemId] = itemDoc.data();
            }
          } catch (error) {
            console.error("Error fetching item:", error);
          }
        }
      }

      setItemsData(itemsMap);
      setLoadingItems(false);
    };

    // Only fetch if there is data
    if (paginatedData.length > 0) {
      fetchItemsData();
    } else {
      setLoadingItems(false);
    }
  }, [paginatedData]);

  const renderCell = useCallback(
    (transaction, columnKey) => {
      const cellValue = transaction[columnKey];

      switch (columnKey) {
        case "amount":
          return (
            <Chip color={cellValue < 0 ? "danger" : "success"}>
              {`${cellValue}€`}
            </Chip>
          );
        case "date":
          return (
            <span className="text-default-400">
              {transaction.date?.toDate().toLocaleString()}
            </span>
          );
        case "reason":
          return <span className="capitalize">{cellValue}</span>;
        case "itemId":
          const itemData = itemsData[cellValue];
          return itemData ? (
            <div className="flex gap-2">
              <Image
                className="aspect-square h-20 object-cover"
                src={itemData.images?.[0] || ""}
              />
              <span className="font-bold hidden md:block">
                {itemData.title}
              </span>
            </div>
          ) : (
            <Spinner />
          );
        default:
          return cellValue;
      }
    },
    [itemsData]
  );

  const handleRowClick = (itemId) => {
    if (!itemId) return;

    // Update the search parameters with the selected itemId
    searchParams.set("item", itemId);
    setSearchParams(searchParams);

    // Optional: navigate to the updated URL
    navigate(`?${searchParams.toString()}`);
  };

  if (loadingItems) return <Spinner />;

  // Display the message when there is no data
  if (!data || data.length === 0) {
    return (
      <Link
        to="/home"
        className="hover:bg-default-100 rounded-md flex justify-center items-center h-64"
      >
        <h2 className="text-xl font-semibold">Commencez à revendre</h2>
      </Link>
    );
  }

  return (
    <Table
      removeWrapper
      hideHeader
      aria-label="Transactions table with pagination"
      bottomContent={
        <div className="flex w-full justify-center">
          <Pagination
            isCompact
            showControls
            showShadow
            color="secondary"
            page={page}
            total={totalPages}
            onChange={(newPage) => {
              setLoadingItems(true);
              setItemsData({});
              setPage(newPage);
            }}
          />
        </div>
      }
      classNames={{
        wrapper: "min-h-[222px]",
      }}
    >
      <TableHeader columns={columns}>
        {(column) => (
          <TableColumn key={column.uid} align="start">
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody items={paginatedData}>
        {(item) => (
          <TableRow
            key={item.id}
            onClick={() => handleRowClick(item.itemId)}
            className="hover:bg-default-100 !rounded-lg overflow-x-scroll cursor-pointer transition-colors"
          >
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
